import {UIComponent} from "../../infra/UIComponent";
import {PlayerColors} from "../../../../common/common-consts";

export class ColorChooser extends UIComponent {

    private opened = false;

    constructor(element: HTMLElement, private selectedColor: string, private selectionCB: (selected: string) => void) {
        super(element);
    }

    initElement() {
        this.element.innerHTML = `
            <div class="color-chooser">
                <button class="color-chooser-current-selection" style="background-color: ${this.selectedColor}; border: 1px solid black">Select Color</button>
                <div class="options">
                    ${this.arrayToHTML(PlayerColors, color => `
                          <button class="color-option" style="background-color: ${color}">${this.selectedColor === color ? '&#xF633' : ''}</button>
                    `)}
                </div>
            </div>
        `;

        this.addEventListener('.color-chooser-current-selection', 'click', (evt) => {
            this.opened = !this.opened;
            if (this.opened) {
                setTimeout(() => {
                    const onClickOutside = () => {
                        this.opened = false;
                        this.updateElement();
                        window.removeEventListener('click', onClickOutside);
                    }
                    window.addEventListener('click', onClickOutside);
                })
            }
            this.updateElement();
        })

        this.addEventListener('.color-option', 'click', (evt) => {
            this.selectionCB((evt.target as HTMLElement).style.backgroundColor);
        })
    }

    updateElement() {
        super.updateElement();
        this.setElementDisplayMode('.options', this.opened ? 'flex' : 'none');

    }
}