import './lobby.scss';
import {UIComponent} from "../../infra/UIComponent";
import {CreateGameData, ServerCommand} from "../../../../common/server-command";
import {GameStage, ListGamesResponseData} from "../../../../common/common-types";
import {StrategusGame} from "../Game";
import {Tab, Tabs} from "../../infra/Tabs";
import {UserDialog} from "../UserDialog";

export class Lobby extends UIComponent {

    private gamesList: ListGamesResponseData[] = [];
    private cb: (gameId: string) => void = () => {/**/};
    private gotGames = false;
    private tabs: Tabs | null = null;
    private tabsConfig: Tab[] = [
        {
            id: 'onGoing',
            title: 'Ongoing Games',
            emptyListMsg: 'No games are currently going on',
            dataGetter: () => this.gamesList.filter(g => g.stage === GameStage.OnGoing),
            renderer: (list) => this.renderGamesList(list)
        },
        {
            id: 'inSeating',
            title: 'In Seating Stage (Join a Game!)',
            emptyListMsg: 'No games are currently on Seating stage',
            dataGetter: () => this.gamesList.filter(g => g.stage === GameStage.Seating),
            renderer: (list) => this.renderGamesList(list)
        },
        {
            id: 'savedGames',
            title: 'Your Saved Games',
            emptyListMsg: 'You have not saved any games yet',
            dataGetter: () => this.gamesList.filter(g => g.stage === GameStage.Saved),
            renderer: (list) => this.renderGamesList(list)
        },
    ]

    constructor(selector: string | HTMLElement, private game: StrategusGame) {
        super(selector, {startHidden: true});
    }

    refreshGames() {
        this.gotGames = false;
        this.game.serverComm.execCommandAndGetResponse(ServerCommand.ListGames).then(games => {
            if (this.game.app.router.getCurrentComponent() !== this) {
                return;
            }

            this.gotGames = true;
            this.gamesList = games;
            const tabWithData = this.tabsConfig.find(tab => tab.dataGetter?.().length);
            if (tabWithData) {
                this.tabs?.setCurrentTab(tabWithData);
            }
            this.updateElement();
        })
    }

    onSelection(cb: (gameId: string) => void) {
        this.cb = cb;
    }

    initElement(): void {
        //
    }

    updateElement() {
        super.updateElement();

        this.element.innerHTML = `
            <div class="lobby-root">
                <div class="user-menu"><i class="bi bi-person-circle"></i> ${this.game.userData?.nickname}</div>
                <h1>Hello, ${this.game.userData?.nickname}. Welcome to Strategus Lobby</h1>
                <div class="tabs"></div>
                <div class="outer-games-lists-wrap">
                    ${!this.gotGames ? '<div class="loading-text">Loading Games...</div><svg width="240" height="240" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_ajPY{transform-origin:center;animation:spinner_AtaB .75s infinite linear}@keyframes spinner_AtaB{100%{transform:rotate(360deg)}}</style><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" class="spinner_ajPY"/></svg>' : ''}
                    ${this.gotGames ? this.tabs?.renderTabContent() : ''}
                </div>
                <div><button id="new-game-button">Start a new game!</button></div>
                <div><button id="read-instructions-button">New to Strategus? Read the instructions here.</button></div>
                <div><button id="start-demo-game">Start and watch an <b>ultra high-speed</b> "demo game" played by AI</button></div>
            </div>
        `;

        if (!this.tabs) {
            this.tabs = new Tabs(() => this.querySelector('.tabs') as HTMLElement, this.tabsConfig);
            this.addSubComponent(this.tabs);
        }
        else {
            this.tabs.updateElement();
        }

        this.addEventListener('.user-menu', 'click', evt => {
            new UserDialog(this.game, false);
        });

        this.addEventListener('button', 'click', evt => {
            const btnId = (evt.target as HTMLElement).id;
            switch (btnId) {
                case 'read-instructions-button': {
                    this.game.showInstructionsDialog();
                    break;
                }
                case 'start-demo-game': {
                    this.startDemoGame();
                    break;
                }
                default: {
                    const clickedBtn = (evt.target as HTMLElement);
                    this.cb(clickedBtn.id === 'new-game-button' ? '' : clickedBtn.getAttribute('data-game-id') || '');
                    this.hide();
                }
            }
        })
    }

    private renderGamesList(list: ListGamesResponseData[]) {
        return list.length ? `
            <div class="games-list-wrap" style="display: ${list.length ? 'inline-block' : 'none'}">
                <div class="games-list">
                    ${this.arrayToHTML(list, game => `
                        <div><button data-game-id="${(game.stage === GameStage.Saved ? `saved:` : '') + game.id}">${game.name} [${game.numberOfPlayers} players, ${game.stage !== GameStage.Seating ? 'cycle #' + game.cycleNumber : 'Seating'}]</button></div>
                    `)}
                </div>
            </div>
        ` : '';
    }

    private startDemoGame() {
        const descriptor: CreateGameData = {
            name: this.game.userData?.nickname + '\'s Demo Game',
            speed: 0.1,
            layout: 1,
            gridSize: '6x4',
            cyclesLimit: 900,
            players: [
                { isAI: true, name: 'Robo-bobo', color: 'green', autoMover: 1, initialGoldCoins: 10000000 },
                { isAI: true, name: 'AI Wizard', color: 'orangered', autoMover: 1, initialGoldCoins: 10000000 },
                { isAI: true, name: 'Zarathustra', color: 'purple', autoMover: 1, initialGoldCoins: 10000000 },
                { isAI: true, name: 'The Blue Empire', color: 'dodgerblue', autoMover: 1, initialGoldCoins: 10000000 },
                { isAI: true, name: 'Sonya Zax', color: 'darkslateblue', autoMover: 1, initialGoldCoins: 10000000 },
                { isAI: true, name: 'Lady Algo', color: 'deeppink', autoMover: 1, initialGoldCoins: 10000000 }
            ]
        }

        this.game.startNewGame(descriptor);
    }
}