import {PlayerId, RegionId, TransportId, TransportType} from "./common-types";

export enum ServerCommand {
    GetFeatureFlags,
    RegisterTempUser,
    SetTempUserData,
    CreateGame,
    ChangeGameName,
    LeaveGame,
    ConnectToGame,
    Seat,
    PauseResume,
    StartResume,
    SaveGame,
    PlayerCommand,
    ListGames,
    GetWeaponList,
    SetSpeed,
}

export enum PlayerCommandId {
    Draft,
    Move,
    BuyWeapon,
    FireMissile,
    Retreat,
    ChangeRegionName,
    Resign
}

export interface DraftCommand {
    amount: number;
    regionId: RegionId;
}

export interface MoveCommand {
    sourceId: RegionId;
    targetId: RegionId;
    type: TransportType;
    amount: number;
}

export interface BuyWeaponCommand {
    weaponSymbol: string;
    quantity: number;
    regionId: RegionId;
}

export interface FireMissileCommand {
    missileSymbol: string;
    sourceId: RegionId;
    targetId: RegionId;
}

export interface RetreatCommand {
    transportId: TransportId;
    targetId: RegionId;
}

export interface ChangeRegionNameCommand {
    regionId: RegionId;
    newName: string;
}

export interface ResignCommand {
    playerId: PlayerId;
}

export interface PlayerCommandData {
    cmdId: PlayerCommandId;
    cmdData: MoveCommand | DraftCommand | BuyWeaponCommand | FireMissileCommand | RetreatCommand | ChangeRegionNameCommand | ResignCommand;
}

export interface IAutoMover {
    autoMove(): void;
    setNextAutoMove(): void;
}

export enum AutoMoverType {
    Default,
    Smart
}

export interface PlayerOptions {
    id?: string;
    alliance?: string | null;
    isAI: boolean;
    name: string;
    color: string;
    autoMover?: AutoMoverType;
    userId?: string | null;
    initialGoldCoins?: number;
}

export enum BoardLayout {
    Islands,
    Grid
}

export interface CreateGameData {
    name?: string,
    speed: number,
    layout: BoardLayout,
    gridSize?: string,
    cyclesLimit: number,
    players: PlayerOptions[]
}

