import {Router} from "../infra/Router";
import {Lobby} from "./Lobby";
import {CreateNewGame} from "./CreateNewGame";
import {SeatingArea} from "./SeatingArea";
import {Helpers} from "../../../common/Helpers";
import {Board} from "./Board";
import {ServerCommand} from "../../../common/server-command";
import {StrategusGame} from "./Game";

export class App {
    get router(): Router {
        return this._router;
    }
    get game(): StrategusGame {
        return this._game;
    }
    private _router: Router = new Router();
    private _game: StrategusGame;

    constructor() {
        this._game = new StrategusGame(this);
        this._game.whenInitDone.then (() => {
            this.initRouter();
        })
    }

    private initRouter() {
        this._router.setRoutes([
            {
                url: '/',
                component: (elem) => new Lobby(elem, this.game),
                onEnter: (comp) => {
                    const lobby = comp as Lobby;
                    lobby.refreshGames();
                    lobby.onSelection(gameId => {
                        if (gameId) {
                            if (!gameId.startsWith('saved:')) {
                                this._router.setRoute(`/board?gameId=${gameId}`);
                            }
                            this.game.connectToGame(gameId);
                        }
                        else {
                            this._router.setRoute('/new-game');
                        }
                    });
                    lobby.show();
                }
            },
            {
                url: '/new-game',
                component: (elem) => new CreateNewGame(elem, this.game),
                onEnter: (comp) => {
                    const form = comp as CreateNewGame;
                    form.show();
                }

            },
            {
                url: '/seating-area',
                component: (elem) => new SeatingArea(elem, this.game),
                onEnter: (comp) => {
                    const area = comp as SeatingArea;
                    area.show();

                    const gameId = Helpers.queryParams()['gameId'];
                    if (gameId) {
                        this.game.connectToGame(gameId);
                    }
                }
            },
            {
                url: '/board',
                component: (elem) => {
                    this.game.createBoard(elem);
                    return this.game.board;
                },
                onEnter: (comp) => {
                    comp.show();
                    const gameId = Helpers.queryParams()['gameId'];

                    if (gameId) {
                        this.game.connectToGame(gameId);

                        this.game.gameOn = true;
                    }
                },
                onLeave: (comp) => {
                    (comp as Board).clearBoard();
                    this.game.leave();
                },
                classNames: ['board-root']
            }
        ])

        this._router.setOutlet('#router-outlet');
    }
}