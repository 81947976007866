import './region.scss';
import {BoardObject} from "../BoardObject";
import {StrategusGame} from "../Game";
import {RegionControls} from "./RegionControls";
import {Dictionary} from "lodash";
import {BoardObjectType} from "../BoardObject/types";
import {PlayerData, RegionData, RegionId, WeaponType} from "../../../../common/common-types";
import {PlayerCommandId} from "../../../../common/server-command";
import {ContentEditable} from "../../infra/ContentEditable";

export class Region extends BoardObject {
    get regionData(): RegionData | null {
        return this.game.regionsData.find(rd => rd.regionId === this.regionId) || null;
    }

    get currentOwner(): PlayerData | null {
        return this.game.getPlayerDataById(this.regionData?.currentOwner || null);
    }

    get weapons(): Dictionary<number> {
        return this.regionData?.weapons || {};
    }

    private controls: RegionControls | null = null;

    public regionId: RegionId;

    private _lastOwner: PlayerData | null = null;

    public mapSvg: SVGElement | null = null;

    private regionNameEditor: ContentEditable | null = null;

    constructor(game: StrategusGame, initialData: object = {}) {
        super(game, initialData);
        this._type = BoardObjectType.Region;
        this.regionId = (initialData as any).regionId; /* eslint-disable-line @typescript-eslint/no-explicit-any*/
    }

    public initElement() {
        super.initElement();

        if (!this.regionData) {
            return;
        }

        this.element.innerHTML = `
            <div class="region-title">
                <div class="region-name">${ this.regionData.regionName }</div>            
                <div class="region-value"> [${ this.regionData.value }pts]</div>
                <div class="surrender-flag"><i class="bi bi-flag-fill"></i></div>
                <div class="radiation-icons">
                    <div class="radiation-icon-right"><i class="bi bi-radioactive"></i></div>
                    <div class="radiation-icon-left"><i class="bi bi-radioactive"></i></div>
                </div>
            </div>
            <div class="region-controls"></div>
        `;

        this.controls = new RegionControls(this, '.region-controls');
        this.addSubComponent(this.controls);

        this.element.addEventListener('mouseenter', () => this.element.style.zIndex = '1');
        this.element.addEventListener('mouseleave', () => this.element.style.zIndex = '');

        this.regionNameEditor = new ContentEditable(this.querySelector('.region-name') as HTMLElement, {
            onChange: (newName: string) => {
                this.game.sendPlayerCommand({
                    cmdId: PlayerCommandId.ChangeRegionName,
                    cmdData: {
                        regionId: this.regionId,
                        newName
                    }
                })
            },
            enabledWhen: () => this.game.userId === this.currentOwner?.userId,
            getSavedValue: () => this.regionData?.regionName as string
        });
    }

    public updateElement() {
        super.updateElement();

        if (!this.regionData) {
            return;
        }

        if (this.game.board.selectedObject === this) {
            this.element.classList.add('selected');
        }
        else {
            this.element.classList.remove('selected');
        }

        this.element.style.backgroundColor = this.currentOwner ? this.currentOwner.color : 'darkgrey';

        if (this.mapSvg) {
            this.mapSvg.style.color = this.currentOwner ? this.currentOwner.color : 'darkgrey';
        }

        this.element.style.boxShadow = `gold 0px 0px ${Math.min(this.regionData.radiation ? 5 + this.regionData.radiation : 0, 100)}px ${Math.min(this.regionData.radiation, 40)}px`

        this.withElements('.region-value', div => {
            div.innerText = ` [${ this.regionData?.value }pts]`;
        })

        if (!this.regionNameEditor?.editing) {
            this.withElements('.region-name', div => {
                div.innerText = this.regionData?.regionName as string;
            })
        }

        this.setElementDisplayMode('.radiation-icons', this.regionData.radiation ? 'block' : 'none');

        this.setElementDisplayMode('.surrender-flag', this.currentOwner?.surrendered ? 'inline-block' : 'none');
    }

    public draft(amount: number) {
        this.game.sendPlayerCommand({
            cmdId: PlayerCommandId.Draft,
            cmdData: {
                amount,
                regionId: this.regionId
            }
        });
    }

    public installWeapon(weaponSymbol: string, quantity: number) {
        this.game.sendPlayerCommand({
            cmdId: PlayerCommandId.BuyWeapon,
            cmdData: {
                weaponSymbol,
                quantity,
                regionId: this.regionId
            }
        })
    }

    public onCycle() {
        if (this._lastOwner?.id !== this.currentOwner?.id) {
            this.escape();
        }
        this._lastOwner = this.currentOwner;
    }

    public escape() {
        this.controls?.escape();
    }

    public fireMissile(selectedRegion: Region, missileSymbol: string) {
        this.game.sendPlayerCommand({
            cmdId: PlayerCommandId.FireMissile,
            cmdData: {
                missileSymbol,
                sourceId: this.regionId,
                targetId: selectedRegion.regionId
            }
        })
    }

    public getAvailableWeaponByType(weaponType: WeaponType): string[] {
        return Object.keys(this.weapons).filter(symbol => {
            return this.weapons[symbol] > 0 && this.game.getWeaponsDescriptorBySymbol(symbol)?.type === weaponType;
        });
    }

    public getAvailableMissiles(): string[] {
        return this.getAvailableWeaponByType(WeaponType.Missile)
    }

    public getDistanceToRegion(region: Region) {
        const myPos = this.getPosition();
        const otherPos = region.getPosition();
        return Math.sqrt(Math.pow(otherPos.x - myPos.x, 2) + Math.pow(otherPos.y - myPos.y, 2));
    }
}