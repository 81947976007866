import './dialog.scss';
import {UIComponent} from "../UIComponent";
import {Dictionary} from "lodash";

export interface DialogOptions {
    backdropClickCloses?: boolean,
    className?: string
}

const defaultOptions: DialogOptions = {
    backdropClickCloses: true
}

export class Dialog extends UIComponent {

    public readonly whenClosed: Promise<void>;
    public readonly whenReady: Promise<void>;
    private _onCloseCB: () => void = () => {/**/};
    private _onReadyCB: () => void = () => {/**/};
    private options: DialogOptions;

    constructor(private content: string, private actions: Dictionary<() => void> = {}, options: DialogOptions = defaultOptions) {
        super((() => {
            const existing = document.querySelector('.dialog-main-container');

            if (existing) {
                return existing as HTMLElement;
            }

            const e = document.createElement('div');
            e.className = 'dialog-main-container';
            document.body.appendChild(e);
            return e;
        })());

        this.options = Object.assign({}, defaultOptions, options);

        this.whenClosed = new Promise(resolve => {
            this._onCloseCB = () => resolve();
        })

        this.whenReady = new Promise(resolve => {
            this._onReadyCB = () => resolve();
        })
    }

    private onKeyDown = (evt: KeyboardEvent) => {
        if (evt.key === 'Escape') {
            this.close();
        }
    }

    destroy() {
        super.destroy();

        window.removeEventListener('keydown', this.onKeyDown);
    }

    initElement(): void {

        window.addEventListener('keydown', this.onKeyDown);

        const actionsHTML = this.arrayToHTML(Object.entries(this.actions), ([actionLabel, actionCB]) => `
            <button class="dialog-button" data-action-label="${actionLabel}">${actionLabel}</button>   
        `);

        this.element.innerHTML = `
            <div class="dialog-inner-container">
                <div class="dialog-custom-content">${this.content}</div>
                ${Object.entries(this.actions).length ? `<div class="dialog-actions">${actionsHTML}</div>` : ''}
            </div>
        `;

        this.setClickListeners({
            '': evt => {
                if (evt.target === this.element && this.options.backdropClickCloses) {
                    this.close();
                }
            },
            '.dialog-button': evt => {
                const action = (evt.target as HTMLElement).getAttribute('data-action-label');
                const cb = action && this.actions[action];
                cb && cb();
            }
        })

        this.addEventListener('input.close-on-enter', 'keydown', evt => {
            if ((evt as KeyboardEvent).code === 'Enter') {
                this.close();
            }
        })

        this.withElements('.dialog-inner-container', elem => {
            if (this.options.className) {
                elem.classList.add(this.options.className)
            }
        })

        this._onReadyCB();
    }

    public getButton(label: string): HTMLElement | null {
        return this.element.querySelector(`[data-action-label=${label}]`);
    }

    public close() {
        this._onCloseCB();
        this.destroy();
    }

    public resetScroll() {
        this.withElements('.dialog-body', elem => elem.scrollTop = 0)
    }
}