import './create-new-game.scss';
import {UIComponent} from "../../infra/UIComponent";
import {StrategusGame} from "../Game";
import {AutoMoverType, CreateGameData, PlayerOptions} from "../../../../common/server-command";
import {PlayersRubricsComponent} from "../PlayersRubricsComponent";
import {PlayerColors} from "../../../../common/common-consts";

export class CreateNewGame extends UIComponent {
    private players: PlayerOptions[] = [];

    private playersRubrics: PlayersRubricsComponent | null = null;

    private advancedOptions = false;

    private readonly localStorageKey = 'lastCreateGameData';

    private readonly playersDefaults: PlayerOptions[] = [
        {
            isAI: false,
            name: this.game.userData?.nickname || 'Player 1',
            color: PlayerColors[0],
            autoMover: AutoMoverType.Smart
        },
        {
            isAI: true,
            name: 'Player 2',
            color: PlayerColors[1],
            autoMover: AutoMoverType.Smart
        },
        {
            isAI: true,
            name: 'Player 3',
            color: PlayerColors[2],
            autoMover: AutoMoverType.Smart
        },
        {
            isAI: true,
            name: 'Player 4',
            color: PlayerColors[3],
            autoMover: AutoMoverType.Smart
        },
        {
            isAI: true,
            name: 'Player 5',
            color: PlayerColors[4],
            autoMover: AutoMoverType.Smart
        },
        {
            isAI: true,
            name: 'Player 6',
            color: PlayerColors[5],
            autoMover: AutoMoverType.Smart
        }
    ];

    constructor(selector: string | HTMLElement, private game: StrategusGame) {
        super(selector, {startHidden: true});
    }

    initElement(): void {

        this.players = this.playersDefaults.map(p => ({...p})).slice(0, 2);

        this.element.innerHTML = `
            <div class="new-game-form">
                <h1>Start a new game of Strategus!</h1>
                <div class="game-options-container">
                    <div>
                        <label for="game-name" class="label">Game Name:</label>
                        <input type="text" class="game-name-input" id="game-name"/>
                    </div>
                    <div>
                        <label for="num-players-input" class="label">Number of players</label>
                        <input min="2" max="6" type="number" class="num-players-input" id="num-players-input"/>
                    </div>
                    <div class="board-layout-select">
                        <label for="board-layout" class="label">Board layout:</label>
                        <select class="board-layout dropdown-select" name="board-layout" id="board-layout">
                          <option value="islands">Islands</option>
                          <option value="grid">Grid</option>
                        </select>
                    </div>
                    <div class="grid-size-select">
                        <label for="grid-size" class="label">Grid Size:</label>
                        <select class="grid-size dropdown-select" name="grid-size" id="grid-size">
                          <option value="3x2">3x2</option>
                          <option value="3x3">3x3</option>
                          <option value="4x3" selected>4x3</option>
                          <option value="4x4">4x4</option>
                          <option value="5x4">5x4</option>
                          <option value="6x4">6x4</option>
                        </select>
                    </div>
                    <div class="game-duration-select">
                        <label for="game-duration" class="label">Game Duration:</label>
                        <select class="game-duration dropdown-select" name="game-duration" id="game-duration">
                          <option value="900">900 Cycles</option>
                          <option value="1800" selected>1800 Cycles</option>
                          <option value="3600">3600 Cycles</option>
                          <option value="0">Till only one power left</option>
                        </select>
                    </div>
                    <div class="show-advanced-options"><button class="show-advanced-btn">More options...</button></div>
                    <div class="speed-select">
                        <label for="Seconds per cycle" class="label">Seconds per cycle: <i class="bi bi-question-square-fill" title="The speed of the game.\nAbove 1 = Slower\nBelow 1 = Faster\nNote that small values may result\nin a game that is impossible to play."></i></label>
                        <input min="0.1" max="2" maxlength="1" step="0.1" type="number" class="speed-input" id="speed-input"/>
                    </div>
                </div>
                <div></div><div class="players-wrap"></div>
                <div><button class="start-game"></button></div>
                <div><button class="reset">Reset to default game settings</button></div>
                <div><button class="back-to-lobby">Back to Lobby</button></div>
            </div>
        `;

        this.createBindings({
            gameName: '.game-name-input',
            numPlayers: '.num-players-input',
            boardLayout: '.board-layout',
            gridSize: '.grid-size',
            gameDuration: '.game-duration',
            cyclesPerSecond: '.speed-input',
        })

        this.setClickListeners({
            '.back-to-lobby': () => {
                this.hide();
                this.game.showLobby();
            },
            '.reset': () => {
                localStorage.removeItem(this.localStorageKey);
                this.initElement();
            },
            '.show-advanced-btn': () => {
                this.advancedOptions = true;
                this.updateElement();
            },
            '.start-game': () => {
                this.hide();
                const createGameData = this.getCreateGameData();
                localStorage.setItem(this.localStorageKey, JSON.stringify(createGameData))
                this.game.startNewGame(createGameData);
            }
        });


        this.bindings.boardLayout.onChange(() => {
            this.updateElement();
        })

        this.bindings.cyclesPerSecond.onChange(() => {
            this.updateElement();
        })

        this.playersRubrics = new PlayersRubricsComponent('.players-wrap');
        this.addSubComponent(this.playersRubrics);

        this.initNumPlayersInput();

        this.bindings.gameName.value = this.getDefaultGameName();
        this.bindings.numPlayers.value = 2;
        this.bindings.cyclesPerSecond.value = '1';

        const prevStr = localStorage.getItem(this.localStorageKey);

        if (prevStr) {
            this.setDataToElements(JSON.parse(prevStr));
        }
        else {
            this.updateElement();
        }
    }

    private getDefaultGameName() {
        return `${this.game.userData?.nickname !== 'Player' ? this.game.userData?.nickname + '\'s ': ''} Game`;
    }

    private getCreateGameData(): CreateGameData {
        return {
            name: this.bindings.gameName.value || this.getDefaultGameName(),
            speed: this.getSpeed(),
            layout: +this.bindings.boardLayout.props.selectedIndex,
            ...(this.bindings.boardLayout.value === 'grid' ? {gridSize: this.bindings.gridSize.value} : {}),
            cyclesLimit: +this.bindings.gameDuration.value,
            players: this.players.map(p => this.numNonUIPlayers() <= 1 ? (p.isAI ? p : {...p, userId: this.game.userId}) : p)
        }
    }

    private setDataToElements(createGameData: CreateGameData) {
        this.bindings.gameName.value = createGameData.name || this.getDefaultGameName();
        this.bindings.cyclesPerSecond.value = createGameData.speed;
        if (createGameData.speed !== 1) {
            this.advancedOptions = true;
        }

        this.bindings.boardLayout.props.selectedIndex = createGameData.layout;
        if (createGameData.gridSize) {
            this.bindings.gridSize.value = createGameData.gridSize;
        }
        this.bindings.gameDuration.value = createGameData.cyclesLimit + '';
        this.bindings.numPlayers.value = createGameData.players.length + '';
        this.players = createGameData.players.map(pl => ({...pl, userId: null}));
        this.updateElement();
    }

    private getSpeed() {
        return Math.min(Math.max(+this.bindings.cyclesPerSecond.value || 1, .1), 2)
    }

    updateElement() {
        super.updateElement();

        this.playersRubrics?.setPlayers(this.players);

        this.withElements('.start-game', startBtn => {
            startBtn.innerText = this.numNonUIPlayers() <= 1 ? 'Start the Game !' : 'Proceed to Seating...';
            startBtn.classList[this.numNonUIPlayers() <= 1 ? 'remove' : 'add']('go-to-seating');
        })

        this.setElementDisplayMode('.grid-size-select', this.bindings.boardLayout.value === 'grid' ? 'block' : 'none');

        this.setElementDisplayMode('.go-to-seating', this.numNonUIPlayers() > 1 ? 'inline-block' : 'none');

        this.setElementDisplayMode('.speed-select', this.advancedOptions ? 'block' : 'none');
        this.setElementDisplayMode('.show-advanced-btn', !this.advancedOptions ? 'inline' : 'none');

        this.withElements('.game-duration-select', elem => {
            const cyclesPerSeconds = +this.getSpeed();
            elem.querySelectorAll('option').forEach(optionElem => {
                const value = +optionElem.value;
                if (value) {
                    optionElem.innerHTML = ((value * cyclesPerSeconds) / 60).toFixed(1);
                    if (optionElem.innerHTML.endsWith('.0')) {
                        optionElem.innerHTML = optionElem.innerHTML.slice(0, -2);
                    }
                    optionElem.innerHTML += ` Minutes (${value} Cycles)`
                }
            });
        })
    }

    protected onMessage(msg: string, data?: any) {
        switch (msg) {
            case 'player-name-change': {
                this.withElements('.start-game', startBtn => {
                    (startBtn as HTMLButtonElement).disabled = !!this.players.find(p => !p.name);
                })
                break;
            }
            case 'ai-checkbox-change': {
                const player = data as PlayerOptions;

                const defaultName = (p: PlayerOptions) => 'Player ' + (this.players.indexOf(p) + 1);

                if (player.isAI && player.name === this.game.userData?.nickname) {
                    player.name = defaultName(player);
                }

                const humans = this.players.filter(p => !p.isAI);

                if (humans.length === 1 && humans[0].name === defaultName(humans[0])) {
                    humans[0].name = this.game.userData?.nickname as string;
                }

                this.updateElement();

                break;
            }
        }
    }

    private numNonUIPlayers() {
        return this.players.filter(p => !p.isAI).length;
    }

    private onChangeNumPlayers(numPlayers: number) {
        this.players = this.playersDefaults.map(p => ({...p})).slice(0, numPlayers).map((player, idx) => {
            return this.players[idx] || player;
        });
        this.updateElement();
    }

    private initNumPlayersInput() {
        const DEFAULT = 2;
        const MIN = 2;
        const MAX = 6;

        let lastValue: string | number = 2;

        this.bindings.numPlayers.onChange(() => {
            const val = +this.bindings.numPlayers.value;
            const newVal = val > MAX ? MAX : val < MIN ? MIN : parseInt(val + '') === val ? val : DEFAULT;
            this.bindings.numPlayers.value = newVal;
            if (newVal != lastValue) {
                this.onChangeNumPlayers(newVal);
            }
            lastValue = newVal
        })
    }

}