import './info-area.scss';
import {StrategusGame} from "../Game";
import {UIComponent} from "../../infra/UIComponent";
import {BoardStatsKey, PlayerStatsKey} from "./types";
import {PlayerData} from "../../../../common/common-types";
import {Helpers} from "../../../../common/Helpers";
import {Dictionary, sortBy, uniq} from "lodash";
import {ContentEditable} from "../../infra/ContentEditable";
import {ServerCommand} from "../../../../common/server-command";
import {Dialog} from "../../infra/Dialog";

export class InfoArea extends UIComponent {

    private lastGoldCoinValue: Dictionary<number> = {};
    private lastGoldCoinDiff: Dictionary<number> = {};
    private gameNameEditor: ContentEditable | null = null;

    constructor(protected game: StrategusGame) {
        super(game.board.getInfoAreaElement());
    }

    initElement(): void {
        this.element.innerHTML = `
            <div class="game-details">
                <div class="game-name">${this.game.name}</div>
                <div class="current-cycle">Current Cycle: ${this.game.cyclesLimit && this.game.currentCycle > this.game.cyclesLimit ? this.game.cyclesLimit : this.game.currentCycle}${this.game.cyclesLimit ? ' / ' + this.game.cyclesLimit : ''}</div>
                <div class="total-value">Total Value Points in Board: ${this.game.board.getBoardStats().totalValuePointsCount}</div>
                <button class="resign-button">Resign This Game!</button>
            </div>
            <div class="score-wrap"><div class="score"></div></div>
            <div class="stats-wrap"><div class="stats"></div></div>
            <div class="game-controls">
                <button class="pause-resume-button">Pause/Resume</button>
                <button class="save-game-button">Save game</button>
                <button class="back-to-lobby">Back to lobby</button>
                <button class="help">Help</button>
            </div>
            <div class="footer">Strategus version 0.0.1 [Dev Stage]\nBy Danny Reiser. Copyright © 2023. All Rights Reserved.</div>
        `

        this.setClickListeners({
            '.pause-resume-button': () => {
                this.game.pauseResume();
            },
            '.back-to-lobby': () => {
                this.game.showLobby();
            },
            '.help': () => {
                this.game.showInstructionsDialog();
            },
            '.save-game-button': () => {
                this.game.save();
            },
            '.resign-button': () => {
                const dlg = new Dialog(`
                <div>
                    <h2>Are you sure you want to resign?</h2>
                    <div class="text">If you're in an alliance, and other players in that alliance are still in the game, they will get all your Regions, and forces.</div>
                    <div class="text">If you're on your own, all your Armed Forces will lay down their weapons, and become Civilians. Your regions will wave the white flag, and will wait to be conquered.</div>
                </div>
            `,{
                    'Yes!': () => {
                        this.game.resign();
                        dlg.close();
                    },
                    'No, we\'ll keep on fighting!': () => {
                        dlg.close();
                    }
                },{className: 'resign-dialog'})
            }
        })

        this.gameNameEditor = new ContentEditable(this.querySelector('.game-name') as HTMLElement, {
            onChange: (newName: string) => {
                this.game.serverComm.execCommand(ServerCommand.ChangeGameName,{
                    gameName: newName
                })
            },
            enabledWhen: () => this.game.userId === this.game.creatorUserId,
            getSavedValue: () => this.game.name
        });


    }

    updateElement(): void {
        if (!this.game.isRunning) {
//            return;
        }

        this.withElements('.game-name', elem => {
            if (!this.gameNameEditor?.editing) {
                elem.innerHTML = this.game.name;
            }
        });

        this.setElementDisplayMode('.resign-button', this.game.hasLocalHumanControllingPlayers() && !this.game.getControllingHumanPlayer()?.surrendered ? 'block' : 'none')

        this.withElements('.current-cycle', elem => {
            elem.innerHTML = `Current Cycle: ${this.game.cyclesLimit && this.game.currentCycle > this.game.cyclesLimit ? this.game.cyclesLimit : this.game.currentCycle}${this.game.cyclesLimit ? ' / ' + this.game.cyclesLimit : ''}`
        });

        this.withElements('.total-value', elem => {
            elem.innerHTML = `Total Value Points on Board: ${this.game.board.getBoardStats().totalValuePointsCount}`;
        });

        this.withElements('.score', score => {
            score.innerHTML = `
                <div class="player-stats score"><div class="stats-type">Score:</div>${this.renderPlayersStats(BoardStatsKey.currentScore)}</div>
            `;
        })

        this.withElements('.stats', stats => {
            stats.innerHTML = `
                <div class="player-stats"><div class="stats-type">Civilians:</div>${this.renderPlayersStats(BoardStatsKey.population)}</div>
                <div class="player-stats"><div class="stats-type">Armed Forces:</div>${this.renderPlayersStats(BoardStatsKey.armyForces)}</div>
                <div class="player-stats"><div class="stats-type">Gold:</div>${this.renderPlayersStats(PlayerStatsKey.goldCoins)}</div>
                <div class="player-stats"><div class="stats-type">Regions Owned:</div>${this.renderPlayersStats(BoardStatsKey.regionsOwned)}</div>
            `;
        })

        this.withElements('.pause-resume-button', elem => {
            const btn = elem as HTMLButtonElement;
            btn.disabled = this.game.gotData && this.game.isRunning && this.game.userId !== this.game.creatorUserId;
            btn.innerText = this.game.gotData && this.game.isRunning ? 'Pause' : 'Resume';
        })
    }

    destroy(): void {
        console.log('Method not implemented.');
    }

    private renderPlayersStats(statProp: BoardStatsKey | PlayerStatsKey) {
        const boardStats = this.game.board.getBoardStats();
        const players: PlayerData[] = this.game.getPlayers() as PlayerData[];
        const alliances: string[] = uniq(this.game.getPlayers().filter(p => !!p.alliance).map(p => p.alliance as string));

        const getValue = (player: PlayerData) => {
            return ['goldCoins'].includes(statProp) ?
                        +player[statProp as PlayerStatsKey].toFixed(4) :
                        boardStats?.[statProp as BoardStatsKey]?.[player.name as string] || 0;
        }

        const getAllianceValue = (alliance: string) => {
            return boardStats?.[statProp as BoardStatsKey]?.[alliance] || 0;
        }

        const all: (PlayerData | string)[] = [...players, ...alliances];

        const total = all.reduce((count: number, agent: PlayerData | string) => {
            return count + (typeof agent === 'string' ? getAllianceValue(agent) : getValue(agent));
        }, 0);

        let renderString = '\n';

        all.sort((a, b) => {
            const valA = typeof a === 'string' ? getAllianceValue(a) : getValue(a);
            const valB = typeof b === 'string' ? getAllianceValue(b) : getValue(b);
            return valA > valB ? -1 : valA < valB ? 1 : 0
        })

        all.forEach((agent: PlayerData | string) => {
            const key = typeof agent === 'string' ? agent : agent.name;

            if (typeof agent === 'string') {
                if (statProp !== BoardStatsKey.currentScore) {
                    return;
                }
            }
            else {

                if (agent.surrendered || ((boardStats?.[BoardStatsKey.armyForces]?.[key] || 0) < 1 && (boardStats?.[BoardStatsKey.population]?.[key] || 0) < 1 && (boardStats?.[BoardStatsKey.regionsOwned]?.[key] || 0) === 0)) {
                    return;
                }
            }

            const value = typeof agent === 'string' ? getAllianceValue(agent) : getValue(agent);

            let change = '';

            if (statProp === 'goldCoins') {
                const last = this.lastGoldCoinValue[key];
                if (last) {
                    const diff = last !== value ? value - last : this.lastGoldCoinDiff[key];
                    const sign = diff > 0 ? '+' : diff < 0 ? '-' : '';
                    change = ` (${sign}${Helpers.abbreviateNumber(Math.abs(diff))})`;
                    this.lastGoldCoinDiff[key] = diff;
                }
                this.lastGoldCoinValue[key] = value;
            }

            const formattedValue = Helpers.abbreviateNumber(value).toUpperCase();
            const relative = (100 * value / total).toFixed(0) + '%';
            const color = typeof agent === 'string' ? 'black' : agent.color;
            const backgroundColor = typeof agent === 'string' ? this.getAllianceGradient(agent) : color;
            renderString += value ? `
                <div class="player-stat" style="color: ${color};">
                    <div class="player-name">${key}:</div>
                    <div class="value">${formattedValue}<span class="value-change">${change}</span></div>
                    <div class="relative-gauge-container">
                        <div class="relative-gauge" style="background: ${backgroundColor}; width: ${total ? relative : '0'}">${total ? relative : ''}</div>
                    </div>
                </div>` : '';
        });
        if (!renderString.trim()) {
            renderString = 'No data';
        }
        return renderString;
    }

    private getAllianceGradient(alliance: string) {
        const players: PlayerData[] = this.game.getPlayers().filter(p => p.alliance === alliance) as PlayerData[];

        if (!players.length) {
            return 'black';
        }

        if (players.length === 1) {
            return players[0].color;
        }

        const gap = 100 / (players.length - 1);
        let percent = 0;
        const gradParts: string[] = [];

        sortBy(players, p => p.color).forEach(player => {
            gradParts.push(`${player.color} ${percent}%`);
            percent += gap;
        })

        return `linear-gradient(90deg, ${gradParts.join(', ')})`;
    }
}