import {Dictionary} from "../common-types";

export class Helpers {

    public static generateUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static getNormallyDistributedRandomNumber(mean: number, stddev: number) {
        const { z0 } = this.boxMullerTransform();

        return z0 * stddev + mean;
    }

    private static boxMullerTransform() {
        const u1 = Math.random();
        const u2 = Math.random();

        const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
        const z1 = Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);

        return { z0, z1 };
    }

    public static abbreviateNumber(num: number) {
        if (!num && num !== 0) {
            return '';
        }

        const strNum: string = num.toString().replace(/[^0-9.]/g, '');
        if (+strNum < 1000) {
            return strNum.includes('.') ? (+strNum).toFixed(2) : strNum;
        }
        const si = [
            {v: 1E3, s: "K"},
            {v: 1E6, s: "M"},
            {v: 1E9, s: "B"},
            {v: 1E12, s: "T"},
            {v: 1E15, s: "P"},
            {v: 1E18, s: "E"}
        ];
        let index;
        for (index = si.length - 1; index > 0; index--) {
            if (+strNum >= si[index].v) {
                break;
            }
        }
        return (+strNum / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
    }

    public static queryParams(): Dictionary<string> {
        let paramsStr = location.hash.split('?')[1] || '';

        if (paramsStr.startsWith('?')) {
            paramsStr = paramsStr.substring(1);
        }

        const parts = paramsStr.split('&');

        return parts.reduce((q, part) => {
            const [param, value] = part.split('=');
            return {
                ...q,
                [param]: value
            }
        }, {})
    }
}