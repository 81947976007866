import {Dictionary, GameStage, MissileData, PlayerData, RegionData, TransportData} from "./common-types";
import {BoardLayout} from "./server-command";

export enum ClientCommand {
    CyclicUpdate,
    MissileExplosion,
    TransportInterceptionAttempt,
    WinnerAnnounced,
    LoserAnnounced,
    GameEnded,
    GameNotFound
}

export interface BoardStats {
    totalValuePointsCount: number,
    regionsOwned: Dictionary<number>,
    population: Dictionary<number>,
    armyForces: Dictionary<number>,
    currentScore: Dictionary<number>
}

export interface FullGameData {
    gameId: string;
    creatorUserId: string;
    stage: GameStage;
    layout: BoardLayout;
    cyclesLimit: number,
    gameName: string,
    currentCycle: number,
    speed: number,
    isRunning: boolean,
    players: PlayerData[],
    boardStats: BoardStats,
    regions: RegionData[],
    transports: TransportData[],
    missiles: MissileData[],
}
