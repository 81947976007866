import './game.scss';
import {Board} from "../Board";
import {CreateGameData, PlayerCommandData, PlayerCommandId, ServerCommand} from "../../../../common/server-command";
import {ServerComm} from "../../infra/ServerComm";
import {BoardStats, ClientCommand, FullGameData} from "../../../../common/client-command";
import {
    GameStage,
    MissileData,
    PlayerData,
    PlayerId,
    RegionData,
    TransportData,
    UserData,
    WeaponDescriptor
} from "../../../../common/common-types";
import {SeatingArea} from "../SeatingArea";
import {Dictionary, merge} from "lodash";
import {Dialog} from "../../infra/Dialog";
import {Toaster} from "../../infra/Toaster";
import {Tab, Tabs} from "../../infra/Tabs";
import {quickGuideText} from "../help/quickGuideText";
import {weaponsText} from "../help/weaponsText";
import {regionsText} from "../help/regionsText";
import {UserDialog} from "../UserDialog";
import {alliancesText} from "../help/alliancesText";
import {App} from "../index";
import {tipsAndTricksText} from "../help/tipsAndTricksText";
import {FeatureFlag} from "../../../../common/feature-flags";

export class StrategusGame {
    get gotData() {
        return !!Object.keys(this._lastData).length;
    }

    get creatorUserId(): string {
        return this._lastData?.creatorUserId || '';
    }

    get userData(): UserData | null {
        return this._userData;
    }
    get featureFlags(): Dictionary<boolean> {
        return this._featureFlags;
    }
    get userId(): string | null {
        return this._userId;
    }

    get stage(): GameStage {
        return this._lastData?.stage || GameStage.Initial;
    }

    get name(): string {
        return this._lastData?.gameName || '';
    }

    get cyclesLimit(): number {
        return this._lastData?.cyclesLimit || 0;
    }

    get missilesData(): MissileData[] {
        return this._lastData?.missiles || [];
    }

    get transportsData(): TransportData[] {
        return this._lastData?.transports || [];
    }

    get regionsData(): RegionData[] {
        return this._lastData?.regions || [];
    }

    get isRunning(): boolean {
        return this._lastData?.isRunning || false;
    }

    get speed(): number {
        return this._lastData?.speed || -1;
    }

    get currentCycle(): number {
        return this._lastData?.currentCycle || 0;
    }

    get board(): Board {
        return this._board as Board;
    }

    public getPlayers(): PlayerData[] {
        return this._lastData?.players || [];
    }

    private _board: Board | null = null;

    public serverComm: ServerComm;
    private _lastData: Partial<FullGameData> = {};

    private weaponList: WeaponDescriptor[] = [];
    private _userId: string | null = null;

    private _featureFlags: Dictionary<boolean> = {};

    public gameOn = false;
    private lastGoneToLobby = 0;

    private _userData: UserData | null = {
        nickname: 'Player'
    };

    public readonly whenInitDone: Promise<void>;

    constructor(public readonly app: App) {
        this.serverComm = new ServerComm(this);

        this.whenInitDone = this.init();

        const getThis = () => this;

        Object.defineProperty(window, 'game', {
            get() {
                return getThis();
            },
            configurable: true
        });

    }

    public setUserData(userData: UserData) {
        this._userData = userData;
    }

    public connectToGame(gameId: string) {
        this.serverComm.execCommand(ServerCommand.ConnectToGame, {gameId});
    }

    private async init() {
        const STORAGE_KEY = 'strategus-temp-user-id';
        this.weaponList = await this.serverComm.execCommandAndGetResponse(ServerCommand.GetWeaponList);

        const existingUserId = localStorage.getItem(STORAGE_KEY);

        const user = await this.serverComm.execCommandAndGetResponse(ServerCommand.RegisterTempUser, {
            existingUserId,
            password: existingUserId ? '' : prompt('Access to the game is currently exclusive to a select few.\nIf you\'re fortunate to be among them, please enter the pass phrase.')
        });

        this._userId = user.userId;
        if (user.userData) {
            this._userData = user.userData;
        }

        if (!this._userId) {
            throw Error('Temporary user registration failed')
        }

        if (!existingUserId || !user.userData) {
            this.showNewUserDialog();
        }

        this._featureFlags = await this.serverComm.execCommandAndGetResponse(ServerCommand.GetFeatureFlags);

        localStorage.setItem(STORAGE_KEY, this._userId as string);
    }

    public showLobby() {
        this.gameOn = false;
        this.app.router.setRoute('/');
        this.lastGoneToLobby = new Date().getTime();
    }

    public sendPlayerCommand(cmd: PlayerCommandData) {
        this.serverComm.execCommand(ServerCommand.PlayerCommand, cmd)
    }

    public getPlayerDataById(id: PlayerId | null | undefined): PlayerData | null {
        if (!id) {
            return null;
        }
        return this.getPlayers().find(player => player.id === id) || null;
    }

    public handleCommand(cmd: ClientCommand, data: any) { /* eslint-disable-line @typescript-eslint/no-explicit-any*/
        switch (cmd) {
            case ClientCommand.CyclicUpdate: {
                if (this.featureFlags[FeatureFlag.SendDiffs]) {
                    this._lastData = merge({}, this._lastData || {}, data);
                    this._lastData.missiles = this._lastData?.missiles?.slice(0, data.missiles?.length || 0);
                    this._lastData.transports = this._lastData?.transports?.slice(0, data.transports?.length || 0);
                    this._lastData.regions?.forEach((rgn: RegionData, idx: number) => {
                        rgn.weaponOrders = rgn.weaponOrders.slice(0, data.regions[idx].weaponOrders?.length || 0);
                    });
                }
                else {
                    this._lastData = data;
                }

                if (this._lastData.stage === GameStage.Seating) {
                    const wasInSeating = this.app.router.getCurrentRoute()?.url === '/seating-area';
                    this.app.router.setRoute(`/seating-area?gameId=${this._lastData.gameId}`, () => {
                        const seating = this.app.router.getCurrentComponent() as SeatingArea;
                        if (seating && seating instanceof SeatingArea) {
                            if (!wasInSeating) {
                                seating.initElement();
                            }
                            setTimeout(() => {
                                seating.initRubrics();
                                seating.updateElement();
                            });
                        }
                    });
                }
                else if (this._lastData.stage === GameStage.Ready) {
                    const counter = document.querySelector('#counter') as HTMLElement;
                    let count = 5;
                    const timeoutFunc = () => {
                        counter.innerText = `Starting in ${count}`;
                        if (count > 0) {
                            count--;
                            setTimeout(timeoutFunc, 1000);
                        }
                        else {
                            counter.style.display = 'none';
                            this.start();
                            this.app.router.setRoute(`/board?gameId=${this._lastData.gameId}`);
                        }
                    }
                    timeoutFunc();
                    counter.style.display = 'block';
                }
                else if (this._lastData.stage === GameStage.OnGoing) {
                    if (!this.gameOn && (!this.lastGoneToLobby || new Date().getTime() - this.lastGoneToLobby > 2000)) {
                        this.gameOn = true;
                        this.app.router.setRoute(`/board?gameId=${this._lastData.gameId}`);
                    }
                }
                this.onCycle();
                break;
            }
            case ClientCommand.MissileExplosion: {
                const missile = this.board.missiles.find(md => md.missileId === data.missileId);
                if (missile) {
                    missile.setPosition(data.position.x, data.position.y);
                    missile.explode();
                }
                break;
            }
            case ClientCommand.TransportInterceptionAttempt: {
                const transport = this.board.transports.find(md => md.transportId === data.transportId);
                if (transport) {
                    transport.interceptionAttempt();
                }
                break;
            }
            case ClientCommand.WinnerAnnounced: {
                if (data.gameId === this._lastData.gameId) {
                    this._board?.showMessage(data.msg, data.color);
                }

                break;
            }
            case ClientCommand.LoserAnnounced: {
                if (data.gameId === this._lastData.gameId) {
                    this._board?.showMessage(data.msg, data.color);
                }

                break;
            }
            case ClientCommand.GameEnded: {
                this.showLobby();

                break;
            }
            case ClientCommand.GameNotFound: {
                this.showLobby();

                break;
            }
        }
    }

    public getBoardStats(): Partial<BoardStats> {
        return this._lastData?.boardStats || {};
    }

    public start() {
        this.serverComm.execCommand(ServerCommand.StartResume);
    }

    public pauseResume() {
        this.serverComm.execCommand(ServerCommand.PauseResume);
    }

    private onCycle() {
        this._board?.onCycle();
    }

    public startNewGame(gameDescriptor: CreateGameData) {
        this.serverComm.execCommand(ServerCommand.CreateGame, gameDescriptor);
    }

    public getWeaponsDescriptors(): WeaponDescriptor[] {
        return this.weaponList || [];
    }

    public getWeaponsDescriptorBySymbol(symbol: string): WeaponDescriptor | null {
        return this.getWeaponsDescriptors().find((desc: WeaponDescriptor) => desc.lettersSymbol === symbol) || null;
    }

    public save() {
        this.serverComm.execCommandAndGetResponse(ServerCommand.SaveGame).then(success => {
            if (success) {
                new Toaster('Game saved successfully !')
            }
        })
    }

    private showNewUserDialog() {
        const dlg = new UserDialog(this);
    }

    public showInstructionsDialog() {
        const dlg = new Dialog(`
            <div class="standard-dialog">
                <div class="dialog-title">
                <div class="tabs"></div>
                </div>
                <div class="dialog-body quick-start-dialog">
                    <div class="tab-content"></div>
                </div>
            </div>
        `,{}, {className: 'instructions-dialog'})

        dlg.whenReady.then(() => {
            const tabs = new Tabs(() => dlg.querySelector('.tabs') as HTMLElement, [
                {
                    id: 'quick',
                    title: 'Quick Guide',
                    renderer: () => `
                        <div class="quick-start-text">${quickGuideText}</div>
                    `
                },
                {
                    id: 'regions',
                    title: 'Regions',
                    renderer: () => `
                        <div class="formatted-help-text">${regionsText}</div>
                    `
                },
                {
                    id: 'weapons',
                    title: 'Weapons',
                    renderer: () => `
                        <div class="formatted-help-text">${weaponsText}</div>
                    `
                },
                {
                    id: 'alliances',
                    title: 'Alliances',
                    renderer: () => `
                        <div class="formatted-help-text">${alliancesText}</div>
                    `
                },
                {
                    id: 'tips',
                    title: 'Tips & Tricks',
                    renderer: () => `
                        <div class="formatted-help-text">${tipsAndTricksText}</div>
                    `
                }
            ]);

            const updateTabContent = () => {
                dlg.withElements('.tab-content', elem => {
                    elem.innerHTML = tabs.renderTabContent();

                    dlg.addEventListener('[data-tab-link]', 'click', (evt) => {
                        tabs.setCurrentTab(tabs.tabs.find(tab => tab.id === (evt.target as HTMLElement).getAttribute('data-tab-link')) as Tab);
                    })
                })
            }
            updateTabContent();
            tabs.onChange(() => {
                updateTabContent();
                tabs.updateElement();
                dlg.resetScroll();
            })
        })
    }

    public resign() {
        this.sendPlayerCommand({
            cmdId: PlayerCommandId.Resign,
            cmdData: {
                playerId: this.getControllingHumanPlayer()?.id as string
            }
        })
    }

    public hasLocalHumanControllingPlayers()  {
        return !!this.getControllingHumanPlayer();
    }

    public getControllingHumanPlayer() {
        return this.getPlayers().find(player => player.userId && player.userId === this.userId);
    }

    public createBoard(elem: HTMLElement) {
        this._board = new Board(this, elem);
    }

    public leave() {
        this.gameOn = false;
        this.serverComm.execCommand(ServerCommand.LeaveGame);
        this._lastData = {};
    }
}