import {kebabCase} from 'lodash';
import {BoardObjectType} from "./types";
import {StrategusGame} from "../Game";
import {UIComponent} from "../../infra/UIComponent";

export abstract class BoardObject extends UIComponent {
    get type(): BoardObjectType {
        return this._type as BoardObjectType;
    }

    protected _type: BoardObjectType | null = null;

    protected constructor(public game: StrategusGame, protected initialData: object = {}) {
        super(game.board.element.ownerDocument.createElement('div'));

        this.handleMouseEvents();
    }

    public setPosition(x: number, y: number) {
        this.element.style.left = x + '%';
        this.element.style.top = y + '%';
    }

    public getPosition() {
        return {
            x: parseFloat(this.element.style.left),
            y: parseFloat(this.element.style.top),
        }
    }

    private handleMouseEvents() {
        this.element.addEventListener('click', () => {
            this.game.board.onObjectClick(this);
        });
    }

    public destroy() {
        super.destroy();
        this.element.setAttribute('data-removed', 'true');
    }

    public initElement() {
        this.element.className = `board-object board-object-type-${kebabCase(this.type)}`
        this.game.board.getPlayAreaElement().appendChild(this.element);
    }

    public updateElement() {
        super.updateElement();
    }

    public abstract onCycle(): void;

}