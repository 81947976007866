import {UIComponent} from "../UIComponent";
import {Dictionary} from "lodash";

export interface Route {
    url: string;
    component: UIComponent | ((elem: HTMLElement) => UIComponent),
    onEnter?: (comp: UIComponent) => void,
    onLeave?: (comp: UIComponent) => void,
    classNames?: string[]
}

export class Router {

    private outletElement: HTMLElement | null = null;
    private callbacks: Dictionary<() => void> = {};
    private $currentComponent: UIComponent | null = null;
    private $currentRoute: Route | null = null;

    constructor(private routes: Route[] = []) {
        window.addEventListener('hashchange', () => {
            if (!this.$currentRoute || this.$currentRoute.url !== this.getCurrentUrl()) {
                this.onUrlChange();
            }
        });
    }

    setRoutes(routes: Route[]) {
        this.routes = routes;
    }

    setOutlet(elementOrSelector: HTMLElement | string) {
        this.outletElement = typeof elementOrSelector === 'string' ? document.querySelector(elementOrSelector) as HTMLElement : elementOrSelector;
        this.sync();
    }

    setRoute(url: string, cb: () => void = () => {}) {
        if (url !== this.getCurrentHash()) {
            this.callbacks[url] = cb;
            location.hash = '#' + url;
            this.onUrlChange();
        }
        else {
            cb();
        }
    }

    getCurrentComponent(): UIComponent | null {
        return this.$currentComponent;
    }

    private onUrlChange() {
        const hash = this.getCurrentHash();
        this.sync();

        if (this.callbacks[hash]) {
            this.callbacks[hash]();
            delete this.callbacks[hash];
        }
    }

    private getCurrentHash() {
        return location.hash ? (location.hash.startsWith('#') ? location.hash.substring(1) : location.hash) : '/';
    }

    private getCurrentUrl() {
        return this.getCurrentHash().split('?')[0];
    }

    public getCurrentRoute(): Route | null {
        return this.routes.find(r => r.url === this.getCurrentUrl()) || null;
    }

    private sync() {
        if (!this.outletElement) {
            return;
        }

        const route = this.getCurrentRoute();

        if (this.$currentRoute && this.$currentRoute === route) {
            return;
        }

        if (this.$currentComponent) {
            this.$currentRoute?.onLeave?.(this.$currentComponent);
            this.$currentComponent.setRootElement(document.createElement('div'));
            this.outletElement.className = '';
        }

        this.outletElement.innerHTML = '';

        if (route) {
            const componentOrComponentGetter = route.component;
            const component = componentOrComponentGetter instanceof UIComponent ? componentOrComponentGetter : componentOrComponentGetter?.(this.outletElement);

            if (component) {
                this.$currentRoute = route;
                this.$currentComponent = component;
                if (route.classNames) {
                    this.outletElement.className = route.classNames.join(' ');
                }
                component.initElement();
                route.onEnter?.(component);
            }
        }
    }
}