export const PlayerColors = [
    'green',
    'orangered',
    'purple',
    'dodgerblue',
    'darkslateblue',
    'deeppink',
    'mediumaquamarine',
    'darkred',
    'yellow',
    'dimgray',
    'blue',
    'indianred'
];