import './missile.scss';
import {BoardObject} from "../BoardObject";
import {StrategusGame} from "../Game";
import {ParticlesExplosionEffect} from "../ParticlesExplosionEffect";
import {BoardObjectType} from "../BoardObject/types";
import {MissileData, MissileId, PlayerData} from "../../../../common/common-types";
import {IInterceptable} from "../Region/types";
import {missileSvg} from "./missile.svg";

export class Missile extends BoardObject implements IInterceptable {
    get owner(): PlayerData {
        return this._owner;
    }

    get missileData(): MissileData | null {
        return this.game.missilesData.find(md => md.missileId === this.missileId) || null;
    }

    private _owner: PlayerData;
    private explosionEffect: ParticlesExplosionEffect | null = null;

    public missileId: MissileId;

    constructor(game: StrategusGame, initialData: object = {}) {
        super(game, initialData);
        this._type = BoardObjectType.Missile;
        this.missileId = (initialData as any).missileId; /* eslint-disable-line @typescript-eslint/no-explicit-any*/
        this._owner = this.game.getPlayerDataById(this.game.missilesData.find(md => md.missileId === this.missileId)?.owner) as PlayerData;
    }

    interceptionAttempt(): void {
        //
    }

    public initElement() {
        super.initElement();

        this.element.innerHTML = `
            <div class="svg-rocket"></div>
            <canvas class="missile-canvas" width="200px" height="200px"></canvas>
        `;

        const canvas = this.element.querySelector('.missile-canvas') as HTMLCanvasElement;

        canvas.style.display = 'none';

        this.withElements('.svg-rocket', elem => {
            elem.innerHTML = missileSvg;
            elem.style.display = 'none';
        })

        this.element.style.color = this._owner.color;

        const descriptor  = this.game.getWeaponsDescriptorBySymbol(this.missileData?.missileSymbol as string);

        this.element.style.filter = descriptor?.isRadioactive ? 'drop-shadow(0px 0px 15px gold)' : '';

        this.explosionEffect = new ParticlesExplosionEffect(canvas);
    }

    updateElement() {
        super.updateElement();

        if (!this.missileData) {
            this.game.board.removeBoardObject(this);
            return;
        }

        this.element.style.transition = `all ${1000 * this.game.speed}ms linear, background-color 50ms`;

        this.withElements('.svg-rocket', elem => {
            if (this.missileData?.angle !== null && !this.explosionEffect?.triggered) {
                elem.style.display = 'block';
                const deg = 180 - (this.missileData?.angle || 0) * 180 / Math.PI;
                elem.style.transform = `rotate(${deg}deg)`;
            }
        })
    }

    public onCycle() {
        //
    }

    public explode() {
        this.withElements('.svg-rocket', elem => {
            elem.innerHTML = '';
        })
        this.withElements('.missile-canvas', elem => {
            elem.style.display = 'block';
        })
        if (!this.explosionEffect?.triggered) {
            this.explosionEffect?.createExplosion(() => {
                this.hide();
            })
        }
    }
}