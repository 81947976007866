import {UIComponent} from "../../infra/UIComponent";
import {Region} from "./index";
import {Helpers} from "../../../../common/Helpers";
import {WeaponDescriptor, WeaponSymbols, WeaponType} from "../../../../common/common-types";

export class WeaponChooser extends UIComponent {
    get missilesSelectionMode(): boolean {
        return this._missilesSelectionMode;
    }

    private readonly weaponDescriptors: WeaponDescriptor[] = this.region.game.getWeaponsDescriptors();
    private _missilesSelectionMode = false;

    constructor(private region: Region, selector: string, private onWeaponChosen = (chosenSymbol: string) => {/**/}) { /* eslint-disable-line @typescript-eslint/no-unused-vars*/
        super(region.element.querySelector(selector) as HTMLElement);
    }

    initElement(): void {
        this.element.innerHTML = this.weaponDescriptors
            .filter(desc => this._missilesSelectionMode ? desc.type === WeaponType.Missile && this.region.getAvailableMissiles().includes(desc.lettersSymbol) : true)
            .map(descriptor => `<button class="btn weapon-chooser-button" data-weapon-symbol="${descriptor.lettersSymbol}" title="${descriptor.name}">${descriptor.lettersSymbol}\n${this._missilesSelectionMode ? '' : Helpers.abbreviateNumber(descriptor.price)}</button>`).join('');

        this.addEventListener('.weapon-chooser-button', 'click', evt => {
            evt.stopPropagation();
            const chosenSymbol = (evt.target as HTMLElement).getAttribute('data-weapon-symbol') as string;
            this._missilesSelectionMode = false;
            this.onWeaponChosen(chosenSymbol);
        })
    }

    updateElement(): void {
        super.updateElement();

        this.weaponDescriptors.forEach(descriptor => {
            this.withElements(`[data-weapon-symbol=${descriptor.lettersSymbol}]`, elem => {
                const btn = elem as HTMLButtonElement;
                btn.disabled = (!this._missilesSelectionMode &&
                                (this.region.currentOwner?.goldCoins || 0) < descriptor.price) ||
                                (descriptor.lettersSymbol === WeaponSymbols.GR &&
                                 this.region.regionData?.currentOwner !== this.region.regionData?.originalOwner);
            })
        })
    }

    public setMissileSelectionMode(mode = true) {
        this._missilesSelectionMode = mode;
        this.initElement();
    }
}
