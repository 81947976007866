import './players-rubrics.scss';
import {UIComponent} from "../../infra/UIComponent";
import {AutoMoverType, PlayerOptions} from "../../../../common/server-command";
import {PlayerColors} from "../../../../common/common-consts";
import {ColorChooser} from "./ColorChooser";

export enum RubricsMode {
    GameCreation,
    Seating
}

export class PlayersRubricsComponent extends UIComponent {

    private players: PlayerOptions[] = [];

    private _mode: RubricsMode = RubricsMode.GameCreation;

    private onSeatCallback = (player: PlayerOptions) => {/**/}; /* eslint-disable-line @typescript-eslint/no-unused-vars*/

    constructor(selector: string | HTMLElement) {
        super(selector, {manualInitialization: true});
    }

    public setMode(mode: RubricsMode) {
        this._mode = mode;
    }

    public onSeat(cb: (player: PlayerOptions) => void) {
        this.onSeatCallback = cb;
    }

    public setPlayers(players: PlayerOptions[]) {
        this.players = players;
        const usedColors: string[] = [];
        this.players.forEach(player => {
            if (usedColors.includes(player.color)) {
                player.color = PlayerColors.find(c => !usedColors.includes(c)) as string;
            }
            usedColors.push(player.color)
        })
        this.updateElement();
    }

    initElement(): void {
        //
    }

    updateElement() {
        super.updateElement();
        this.element.innerHTML = '<div class="players-rubrics-component">' + this.arrayToHTML(this.players, (player: PlayerOptions, idx: number) => `
            <div class="player-rubric" style="background: ${player.color}" data-index="${idx}">
                ${this._mode === RubricsMode.GameCreation ? `
                    <div><input type="text" class="player-name" placeholder="Player Name" value="${player.name}"></div>
                    <div class="color-select-wrap">
                        <div class="color-chooser-root"></div>
                    </div>
                    <label><input type="checkbox" name="is-ai-checkbox" class="is-ai-checkbox checkbox" ${player.isAI ? 'checked' : ''}> AI</label>
                    <div class="auto-mover-select" style="visibility: ${player.isAI ? 'visible' : 'hidden'}">
                        <label for="ai-level">AI Level:</label>
                        <select class="ai-level" name="ai-level">
                            <option value="basic">Basic</option>
                            <option value="smart">Smart</option>
                            <option value="genius" disabled>Genius</option>
                        </select>
                    </div>
                    <div class="alliance-selection">
                        <label><input type="checkbox" name="alliance-checkbox" class="alliance-checkbox checkbox" ${player.alliance ? 'checked' : ''}> Alliance</label>
                        <input style="visibility: ${player.alliance ? 'visible' : 'hidden'}"
                               type="text"
                               class="alliance-name"
                               placeholder="Alliance Name"
                               value="${player.alliance || ''}">
                    </div>
                ` : `
                    <div class="player-name">${player.name + (player.isAI ? ' [AI]' : '')}</div>
                    <div><button class="take-seat-btn">Take This Seat!</button></div>
                `}
            </div>
        `) + '</div>';

        this.withElements('.color-chooser-root', elem => {
            const player = this.getPlayerFromElement(elem);
            this.addSubComponent(new ColorChooser(elem, player.color, (selected) => {
                const oldColor = player.color;
                const newColor = selected;
                const playerWithNewColor = this.players.find(p => p.color === newColor);
                if (playerWithNewColor) {
                    playerWithNewColor.color = oldColor;
                }
                player.color = newColor;
                this.updateElement();
            }));
        })

        this.setElementVisibilityMode('.take-seat-btn', elem => this.getPlayerFromElement(elem).isAI || this.getPlayerFromElement(elem).userId ? 'hidden' : 'visible');

        this.addEventListener('.player-name', 'input', evt => {
            const inputElem = evt.target as HTMLInputElement;
            this.players[+(inputElem.parentElement?.parentElement?.getAttribute('data-index') as string)].name = inputElem.value;
            this.parent?.postMessage('player-name-change');
        })

        this.addEventListener('.is-ai-checkbox', 'input', evt => {
            const inputElem = evt.target as HTMLInputElement;
            const player = this.getPlayerFromElement(inputElem);
            player.isAI = inputElem.checked;
            this.parent?.postMessage('ai-checkbox-change', player);
        })

        this.withElements('.alliance-checkbox', elem => {
            const inputElem = elem as HTMLInputElement;
            const player = this.getPlayerFromElement(inputElem);
            inputElem.disabled = player.isAI && player.autoMover === AutoMoverType.Default;
        })

        this.addEventListener('.alliance-checkbox', 'input', evt => {
            const inputElem = evt.target as HTMLInputElement;
            const player = this.getPlayerFromElement(inputElem);
            player.alliance = inputElem.checked ? 'Alliance 1' : null;
            this.updateElement();
        })

        this.addEventListener('.alliance-name', 'input', (evt) => {
            const inputElem = evt.target as HTMLInputElement;
            const player = this.getPlayerFromElement(inputElem);
            player.alliance = inputElem.value;
        })

        this.withElements('.ai-level', elem => {
            const selectElem = elem as HTMLSelectElement;
            selectElem.selectedIndex = this.getPlayerFromElement(selectElem).autoMover as number;
        })

        this.addEventListener('.ai-level', 'input', evt => {
            const selectElem = evt.target as HTMLSelectElement;
            const player = this.getPlayerFromElement(selectElem);
            player.autoMover = selectElem.selectedIndex;
            if (selectElem.selectedIndex === AutoMoverType.Default) {
                player.alliance = null;
            }
            this.updateElement();
        })

        this.addEventListener('.take-seat-btn', 'click', evt => {
            const inputElem = evt.target as HTMLInputElement;
            const player = this.getPlayerFromElement(inputElem);
            this.onSeatCallback(player);
        })

    }

    private getPlayerFromElement(e: HTMLElement) {
        const indexStr  = (e.parentElement?.parentElement?.getAttribute('data-index') || e.parentElement?.parentElement?.parentElement?.getAttribute('data-index')) as string;
        return this.players[+indexStr];
    }
}