export enum BoardStatsKey {
    regionsOwned= 'regionsOwned',
    population= 'population',
    currentScore= 'currentScore',
    armyForces= 'armyForces'
}

export enum PlayerStatsKey {
    goldCoins= 'goldCoins'
}
