import {Dictionary} from "lodash";

export interface AccessorsObject {
    value: any;
    onChange: (cb: (evt: Event) => void) => void;
    props: any;
    element: HTMLElement;
}

export class Bindings {

    private onChangeCallback: Dictionary<(value: any) => void> = {};

    constructor(private bindingsDefinition: Dictionary<HTMLElement>) {
    }

    public createAccessors() {
        const accessors: Dictionary<AccessorsObject> = {};

        Object.entries(this.bindingsDefinition).forEach(([fieldName, element]) => {
            accessors[fieldName] = {
                element,
                value: undefined,
                props: {},
                onChange: (cb: (evt: Event) => void) => {
                    this.onChangeCallback[fieldName] = cb;
                }
            }
            Object.defineProperty(accessors[fieldName], 'value', {
                get(): any {
                    if (element instanceof HTMLInputElement) {
                        return element.type === 'checkbox' ? element.checked : element.value;
                    }
                    if (element instanceof HTMLSelectElement) {
                        return element.value;
                    }
                    else {
                        return element.innerText
                    }
                },
                set(v: any) {
                    if (element instanceof HTMLInputElement) {
                        if (element.type === 'checkbox') {
                            element.checked = !!v;
                        }
                        else {
                            element.value = v;
                        }
                    }
                    if (element instanceof HTMLSelectElement) {
                        element.value = v;
                    }
                    else {
                        element.innerText = v;
                    }
                }
            })

            if (element instanceof HTMLSelectElement) {
                Object.defineProperty(accessors[fieldName].props, 'selectedIndex', {
                    get() {
                        return element.selectedIndex
                    },
                    set(v: any) {
                        element.selectedIndex = v;
                    }
                });
            }

            element.addEventListener('input', (evt) => {
                this.onChangeCallback[fieldName]?.(evt);
            })
        })
        return accessors;
    }
}




