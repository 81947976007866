import {UIComponent} from "../UIComponent";

export interface ContentEditableOptions {
    onChange?: (value: string) => void,
    enabledWhen?: () => boolean;
    getSavedValue?: () => string;
}

const defaults: ContentEditableOptions = {
    onChange: () => {/**/},
    enabledWhen: () => true,
    getSavedValue: () => ''
}

export class ContentEditable extends UIComponent{
    get editing(): boolean {
        return this._editing;
    }

    private _editing = false;

    constructor(element: HTMLElement, private options: ContentEditableOptions = defaults) {
        super(element);
    }

    initElement(): void {
        this.element.addEventListener('dblclick', evt => {
            if (this.options?.enabledWhen?.()) {
                (evt.target as HTMLElement).contentEditable = 'true';
                (evt.target as HTMLElement).focus();
                this._editing = true;
            }
        })

        const onLeaveEdit = () => {
            if (!this._editing) {
                return;
            }

            const newValue = this.element.innerText.substring(0, 20);
            const currentValue = this.options?.getSavedValue?.() || '';

            if (newValue && newValue !== currentValue) {
                this.options?.onChange?.(newValue);
            }
            else {
                this.element.innerText = currentValue;
            }
            this._editing = false;
            this.element.contentEditable = 'false';
        }

        this.element.addEventListener('keydown', evt => {
            if ((evt as KeyboardEvent).code === 'Enter') {
                evt.preventDefault();
                onLeaveEdit();
            }

            if ((evt.target as HTMLElement).innerText.length > 19 && !['Delete', 'Backspace'].includes((evt as KeyboardEvent).code)) {
                evt.preventDefault();
            }
        })

        this.element.addEventListener('blur', evt => {
            onLeaveEdit();
        })

    }
}