import './transport.scss';
import {BoardObject} from "../BoardObject";
import {MoveDetails} from "../Board/types";
import {Region} from "../Region";
import {StrategusGame} from "../Game";
import {IInterceptable} from "../Region/types";
import {BoardObjectType} from "../BoardObject/types";
import {PlayerData, RegionData, TransportData, TransportId} from "../../../../common/common-types";
import {Helpers} from "../../../../common/Helpers";
import {PlayerCommandId} from "../../../../common/server-command";

export class Transport extends BoardObject implements IInterceptable {
    set battleMode(value: boolean) {
        this._battleMode = value;
        if (this._battleMode) {
            this.element.classList.add('battle');
        }
        else {
            this.element.classList.remove('battle');
        }
    }

    get transportData(): TransportData | null {
        return this.game.transportsData.find(td => td.transportId === this.transportId) || null;
    }

    get moveDetails(): MoveDetails | null {
        if (this.transportData) {
            return {
                type: this.transportData.type,
                source: this.game.regionsData.find(rd => rd.regionId === this.transportData?.sourceId) as RegionData,
                target: this.game.regionsData.find(rd => rd.regionId === this.transportData?.targetId) as RegionData,
                amount: this.transportData.amount,
                owner: this.game.getPlayerDataById(this.transportData.owner) as PlayerData
            }
        }

        return null;
    }

    set battleStationNumber(stationNumber: number) {
        Array.from(this.element.classList).forEach(cls => {
            if (cls.startsWith('battle-station-')) {
                this.element.classList.remove(cls);
            }
        })
        this.element.classList.add('battle-station-' + stationNumber);
    }


    private _battleMode = false;

    public transportId: TransportId;

    constructor(game: StrategusGame, initialData: object = {}) {
        super(game, initialData);
        this._type = BoardObjectType.Transport;
        this.transportId = (initialData as any).transportId; /* eslint-disable-line @typescript-eslint/no-explicit-any*/
    }

    public initElement() {
        super.initElement();

        this.element.innerHTML = `
            <div>
                <div class="amount"></div>
                <div class="type"></div>
                <button class="retreat-button">Retreat</button>
            </div>
        `;

        this.addEventListener('.retreat-button', 'click', () => {
            this.game.board.retreat(this);
        })
    }

    updateElement() {
        super.updateElement();
        this.element.style.backgroundColor = this.moveDetails?.owner?.color || 'white';

        if (!this.transportData) {
            this.game.board.removeBoardObject(this);
            return;
        }

        if (!this._battleMode && this.transportData.battleMode) {
            setTimeout(() => {
                this.setBattleMode();
            }, 100 * this.game.speed);
        }

        if (this._battleMode && !this.transportData.battleMode) {
            this.battleMode = false;
        }

        if (!this.moveDetails) {
            return;
        }

        this.withElements('.amount', amount => {
            if (this.moveDetails) {
                amount.innerText = Helpers.abbreviateNumber(this.moveDetails.amount);
            }
        })

        this.withElements('.type', amount => {
            amount.innerText = this.moveDetails?.type === 'civilian' ? 'CIV' : 'ARMY';
        })

        this.setElementDisplayMode(
            '.retreat-button',
            this._battleMode && !this.moveDetails.owner.isAI ? 'inline-block' : 'none'
        );

        this.element.style.transition = `all ${1000 * this.game.speed}ms linear, background-color 50ms`;

    }

    public onCycle() {

        if (this._battleMode) {
            return;
        }

    }

    public setBattleMode() {
        this.battleMode = true;
        this.battleStationNumber = this.transportData?.battleStation || 0;
    }

    public setRetreatTarget(target: Region) {
        this.game.sendPlayerCommand({
            cmdId: PlayerCommandId.Retreat,
            cmdData: {
                transportId: this.transportId,
                targetId: target.regionId
            }
        })
        this.battleMode = false;
        (this.transportData as TransportData).battleMode = false;
    }

    public interceptionAttempt() {
        this.element.style.backgroundColor = 'white';
        setTimeout(() => {
            this.element.style.backgroundColor = this.moveDetails?.owner.color as string;
        }, 50)
    }
}