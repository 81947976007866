import './tabs.scss';
import {UIComponent} from "../UIComponent";

export interface Tab {
    id: string,
    title: string;
    emptyListMsg?: string;
    dataGetter?: () => any;
    renderer: (data: any) => string;
}

export class Tabs extends UIComponent {
    get tabs(): Tab[] {
        return this._tabs;
    }

    private _currentTab: Tab = this._tabs[0];
    private _onChangeCB: () => void = () => {/**/};

    constructor(element: () => HTMLElement, private _tabs: Tab[]) {
        super(element);
    }

    initElement(): void {
        this.updateElement();
    }

    updateElement() {
        super.updateElement();

        this.element.innerHTML = `
            <div class="tabs-component">
                ${this.arrayToHTML(this._tabs, tab => `<div class="tab ${tab.id === this._currentTab.id ? 'selected' : ''}" data-tab-id="${tab.id}">${tab.title}</div>`)}
            </div>
        `;

        this.addEventListener('.tab', 'click', evt => {
            const tabId = (evt.target as HTMLElement).getAttribute('data-tab-id');
            this._currentTab = this._tabs.find(tab => tab.id === tabId) as Tab;
            this.parent?.updateElement();
            this._onChangeCB();
        })
    }

    renderTabContent() {
        return this._currentTab.renderer(this._currentTab.dataGetter?.()) || `<div class="empty-list-msg">${this._currentTab.emptyListMsg || ''}</div>`;
    }

    setCurrentTab(tab: Tab) {
        if (this._tabs.includes(tab)) {
            this._currentTab = tab;
            this.parent?.updateElement();
            this._onChangeCB();
        }
        else {
            throw 'Invalid Tab'
        }
    }

    onChange(cb: () => void) {
        this._onChangeCB = cb;
    }
}