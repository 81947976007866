import './board.scss';
import {BoardObject} from '../BoardObject';
import {BoardObjectType} from '../BoardObject/types';
import {Region} from '../Region';
import {InfoArea} from "../InfoArea";
import {Transport} from "../Transport";
import {MoveDetails} from "./types";
import {StrategusGame} from "../Game";
import {UIComponent} from "../../infra/UIComponent";
import {Missile} from "../Missile";
import {BoardStats} from "../../../../common/client-command";
import {PlayerData, RegionData, TransportType} from "../../../../common/common-types";
import {PlayerCommandId} from "../../../../common/server-command";
import {sortBy} from "lodash";
import {GameMap} from "../GameMap";
import {FeatureFlag} from "../../../../common/feature-flags";

export class Board extends UIComponent {
    get missileTargetModeWeaponSymbol(): string | null {
        return this._missileTargetModeWeaponSymbol;
    }
    get missileTargetModeFiringRegions(): Region[] {
        return this._missileTargetModeFiringRegions;
    }
    get missileTargetMode(): boolean {
        return this._missileTargetMode;
    }

    get selectedObject(): BoardObject | null {
        return this._selectedObject;
    }

    get boardObjects(): BoardObject[] {
        return this._boardObjects;
    }

    get regions(): Region[] {
        return this.boardObjects.filter(bo => bo.type === BoardObjectType.Region) as Region[];
    }

    get transports(): Transport[] {
        return this.boardObjects.filter(bo => bo.type === BoardObjectType.Transport) as Transport[];
    }

    get missiles(): Missile[] {
        return this.boardObjects.filter(bo => bo.type === BoardObjectType.Missile) as Missile[];
    }

    get targetSelectionMode(): boolean {
        return this._targetSelectionMode;
    }

    private _boardObjects: BoardObject[] = [];
    private _selectedObject: BoardObject | null = null;
    private _targetSelectionMode = false;
    private _missileTargetMode = false;
    private _missileTargetModeFiringRegions: Region[] = [];
    private _missileTargetModeWeaponSymbol: string | null = null;
    private _missileTargetCallbacks: ((region: Region) => void)[] = [];
    private _moveDetails: Partial<MoveDetails> = {}
    private infoArea: InfoArea | null = null;
    private fullMode = false;
    private map: GameMap | null = null;

    constructor(private game: StrategusGame, rootElement: HTMLElement) {
        super(rootElement, {startHidden: true, manualInitialization: true});
    }

    public clearBoard() {
        [...this._boardObjects].forEach(bo => {
            this.removeBoardObject(bo);
        })
    }

    initElement(): void {
        this.element.innerHTML = `
            <div class="play-area">
                <div class="pause-overlay">Game is paused</div>
                <div class="game-map"></div>
                <div class="mini-score-board"></div>
            </div>
            <div class="info-area"></div>
            <div class="game-over-message"></div>
            <div class="info-panel-toggle"><i class="bi bi-layout-sidebar-reverse"></i></div>            
        `;
        this.infoArea = new InfoArea(this.game);

        this.element.tabIndex = 0;
        window.addEventListener('keydown', (evt: KeyboardEvent) => {
            if (evt.key === 'Escape') {
                this.escape();
            }
        })

        this.addEventListener('.info-panel-toggle', 'click', evt => {
            this.setFullMode(!this.fullMode);
        })

        if (this.game.featureFlags[FeatureFlag.Polygons]) {
            this.withElements('.game-map', gameMapRoot => {
                gameMapRoot.style.display = 'block';
                this.map = new GameMap(this, gameMapRoot);
            })
        }

        setTimeout(() => {
            this.updatePauseState();
        })
    }

    protected onShowElement() {
        this.map?.update();
    }

    public escape() {
        this._missileTargetMode = false;
        this._targetSelectionMode = false;
        this._missileTargetModeFiringRegions = [];
        this._missileTargetCallbacks = [];
        this._moveDetails = {};
        this.updateAll();
        this.regions.forEach(rgn => rgn.escape());
        this.hideWinningMessage();
    }

    updateElement(): void {
        this.updateAll();
        this.infoArea?.updateElement();
    }

    destroy(): void {
        (() => null)();
    }

    public getPlayAreaElement(): HTMLElement {
        return this.getSubElement('.play-area');
    }

    public getInfoAreaElement(): HTMLElement {
        return this.getSubElement('.info-area');
    }

    public retreat(transport: Transport) {
        this._moveDetails = {retreating: transport, source: transport.moveDetails?.target};
        this._targetSelectionMode = true;

        this.updateAll();
    }

    public move(fromRegion: Region, amount: number, type: TransportType,  render = true) {
        this._moveDetails.type = type;
        this._moveDetails.owner = fromRegion.currentOwner as PlayerData;
        this._moveDetails.source = fromRegion.regionData as RegionData;
        this._moveDetails.amount = amount;
        this._targetSelectionMode = true;

        if (render) {
            this.updateAll();
        }
    }

    public selectMissileTarget(firingRegion: Region, weaponSymbol: string, onSelected: (region: Region) => void) {
        this._targetSelectionMode = true;
        this._missileTargetMode = true;
        this._missileTargetModeFiringRegions.push(firingRegion);
        this._missileTargetModeWeaponSymbol = weaponSymbol;
        this._missileTargetCallbacks.push(onSelected);

        this.updateAll();
    }

    public onTargetSelected(targetRegion: Region, render = true) {

        if (this._missileTargetMode) {
            this._missileTargetMode = false;
            this._targetSelectionMode = false;
            this._missileTargetModeFiringRegions = [];
            this._missileTargetModeWeaponSymbol = null;
            if (this._missileTargetCallbacks) {
                this._missileTargetCallbacks.forEach(cb => cb(targetRegion));
                this._missileTargetCallbacks = [];
            }
        }


        this._moveDetails.target = targetRegion.regionData as RegionData;

        if (this._moveDetails.retreating) {
            this._moveDetails.retreating.setRetreatTarget(targetRegion);
            this._moveDetails = {};
        }
        else if (this._moveDetails.source && this._moveDetails.target && this._moveDetails.amount) {
            this.game.sendPlayerCommand({
                cmdId: PlayerCommandId.Move,
                cmdData: {
                    sourceId: this._moveDetails.source.regionId,
                    targetId: this._moveDetails.target.regionId,
                    type: this._moveDetails.type as TransportType,
                    amount: this._moveDetails.amount
                }
            })
            this._moveDetails = {};
        }

        this._targetSelectionMode = false;

        if (render) {
            this.updateAll();
        }
    }

    public getMoveDetails(): MoveDetails {
        return this._moveDetails as MoveDetails;
    }

    private getSubElement(selector: string): HTMLElement {
        const e = this.element.querySelector(selector);

        if (!e) {
            throw ('Unexpected Error');
        }

        return e as HTMLElement;
    }

    public onObjectClick(boardObject: BoardObject) {
        Object.defineProperty(window, 'boardObject', {
            value: boardObject,
            writable: true
        });
    }

    public getBoardStats(): Partial<BoardStats> {
        return this.game.getBoardStats() || {};
    }

    public createBoardObject(type: BoardObjectType, initialData: object = {}): BoardObject {
        let object: BoardObject;

        switch (type) {
            case BoardObjectType.Region: {
                object = new Region(this.game, initialData);
                break;
            }
            case BoardObjectType.Transport: {
                object = new Transport(this.game, initialData);
                break;
            }
            case BoardObjectType.Missile: {
                object = new Missile(this.game, initialData);
                break;
            }
            default: {
                throw('Unknown type: ' + type);
            }
        }

        this._boardObjects.push(object);

        return object;
    }

    public removeBoardObject(object: BoardObject) {
        object.destroy();
        if (this._boardObjects.includes(object)) {
            this._boardObjects.splice(this._boardObjects.indexOf(object), 1);
            if (object.type === BoardObjectType.Transport && this._moveDetails.retreating === object) {
                this._targetSelectionMode = false;
                this._moveDetails = {};
            }
        }
    }

    private updateAll() {
        this._boardObjects.forEach((object: BoardObject) => {
            object.updateElement();
        });
    }

    private createOrUpdateMissiles() {
        this.game.missilesData.forEach(missileData => {
            const existing = this.missiles.find(t => t.missileId === missileData.missileId);
            let newMissile: Missile | null = null;
            if (existing) {
                existing.updateElement();
            }
            else {
                newMissile = this.createBoardObject(BoardObjectType.Missile, {missileId: missileData.missileId}) as Missile;
            }
            ((existing || newMissile) as Missile).setPosition(missileData.positionX as number, missileData.positionY as number)
        })
    }

    private createOrUpdateTransports() {
        this.game.transportsData.forEach(transportData => {
            const existing = this.transports.find(t => t.transportId === transportData.transportId);
            let newTransport: Transport | null = null;
            if (existing) {
                existing.updateElement();
            }
            else {
                newTransport = this.createBoardObject(BoardObjectType.Transport, {transportId: transportData.transportId}) as Transport;
            }
            ((existing || newTransport) as Transport).setPosition(transportData.positionX as number, transportData.positionY as number)
        })
    }

    private createOrUpdateRegions() {
        let createdRegions = false;
        this.game.regionsData.forEach(regionData => {
            const existing = this.regions.find(r => r.regionId === regionData.regionId);
            if (existing) {
                existing.updateElement();
            }
            else {
                const region = this.createBoardObject(BoardObjectType.Region, {regionId: regionData.regionId});
                region.setPosition(regionData.positionX as number, regionData.positionY as number);
                createdRegions = true;
            }
        })
        if (createdRegions) {
            this.map?.update();
        }
    }

    public onCycle() {

        this.element.querySelectorAll('[data-removed]').forEach(element => {
            element.remove();
        })

        this.createOrUpdateRegions();
        this.createOrUpdateTransports();
        this.createOrUpdateMissiles();

        this._boardObjects.forEach((object: BoardObject) => {
            object.onCycle();
        });

        this.updateAll();
        this.infoArea?.updateElement();

        this.withElements('.mini-score-board', elem => {
            elem.innerHTML = `
                <div>${this.game.cyclesLimit && this.game.currentCycle > this.game.cyclesLimit ? this.game.cyclesLimit : this.game.currentCycle}${this.game.cyclesLimit ? ' / ' + this.game.cyclesLimit : ''}</div>
                ${this.arrayToHTML(sortBy(this.game.getPlayers(), pl => -(this.getBoardStats().currentScore?.[pl.name] || 0)), pl => `
                    <div style="text-align: right; color: ${pl.color}">${this.getBoardStats().currentScore?.[pl.name] || ''}</div>
                `)
                }
            `;
        })

        this.updatePauseState();
    }

    private updatePauseState() {
        this.withElements('.pause-overlay', elem => {
            elem.style.opacity = !this.game.gotData || this.game.isRunning ? '0' : '1';
            elem.style.pointerEvents = !this.game.gotData || this.game.isRunning ? 'none' : 'unset';
        })
        this.infoArea?.updateElement();
    }

    public showMessage(text: string, color: string) {
        const gom = this.element.querySelector('.game-over-message') as HTMLElement;
        gom.innerText = text;
        gom.style.color = color;
        gom.style.display = 'block';

        setTimeout(() => {
            gom.style.opacity = '0.85';
        }, 100)

        setTimeout(() => {
            this.hideWinningMessage();
        }, 10000)
    }

    public hideWinningMessage() {
        const gom = this.element.querySelector('.game-over-message') as HTMLElement;
        gom.style.opacity = '0';
        setTimeout(() => {
            gom.style.display = 'none';
        }, 1000);
    }

    public setFullMode(mode = true) {
        this.fullMode = mode;
        this.element.classList[this.fullMode ? 'add' : 'remove']('full-play-area');
    }
}