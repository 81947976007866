import {Vector} from "./common-types";

export class Polygon {
    get pts(): Vector[] {
        return this._pts;
    }

    constructor(private _pts: Vector[]) {

    }

    public setPoints(pts: Vector[]) {
        this._pts = pts;
    }

    public getClosestPoint(pt: Vector) {
        return this.pts.reduce((closest, candidate) => {
            const distClosest = Math.sqrt(Math.pow(pt.x - closest.x, 2) + Math.pow(pt.y - closest.y, 2));
            const distCandidate = Math.sqrt(Math.pow(pt.x - candidate.x, 2) + Math.pow(pt.y - candidate.y, 2));
            if (distCandidate < distClosest) {
                return candidate;
            }
            return closest;
        }, this.pts[0])
    }

    public isPointInside(point: Vector, includeEdge = false) {
        let crossings = 0;
        for (let i = 0; i < this.pts.length; i++) {
            const vertex1 = this.pts[i];
            const vertex2 = this.pts[(i + 1) % this.pts.length];

            if (includeEdge) {
                // If the point is on an edge of the polygon, consider it inside
                if (vertex1.y === vertex2.y && vertex1.y === point.y &&
                    (vertex1.x <= point.x || vertex2.x <= point.x) &&
                    (vertex1.x >= point.x || vertex2.x >= point.x)) {
                    return true;
                }
            }

            // Ignore vertices that aren't crossed by the ray
            if (vertex1.y > point.y !== vertex2.y > point.y) {
                // Determine the x coordinate of the point where the edge intersects the ray
                const intersectX = ((vertex2.x - vertex1.x) * (point.y - vertex1.y)) / (vertex2.y - vertex1.y) + vertex1.x;
                if (point.x < intersectX) {
                    crossings++;
                }
            }
        }
        return crossings % 2 !== 0;
    }

    area(): number {
        let area = 0;
        const n = this.pts.length;

        for (let i = 0; i < n; i++) {
            const {x: x1, y: y1} = this.pts[i];
            const {x: x2, y: y2} = this.pts[(i + 1) % n];
            area += x1 * y2 - x2 * y1;
        }

        return Math.abs(area) / 2;
    }
}