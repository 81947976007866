import './seating-area.scss';
import {UIComponent} from "../../infra/UIComponent";
import {StrategusGame} from "../Game";
import {PlayerOptions, ServerCommand} from "../../../../common/server-command";
import {PlayersRubricsComponent, RubricsMode} from "../PlayersRubricsComponent";
import {GameStage} from "../../../../common/common-types";
import {Toaster} from "../../infra/Toaster";

export class SeatingArea extends UIComponent {

    private players: PlayerOptions[] = [];

    private playersRubrics: PlayersRubricsComponent | null = null;

    constructor(selector: string | HTMLElement, private game: StrategusGame) {
        super(selector, {startHidden: true, manualInitialization: true});
    }

    initElement(): void {
        this.element.innerHTML = `
            <div class="sitting-area-wrap">
                <h1 class="game-name"></h1>
                <div class="players-wrap-seating"></div>
                <div class="share-link">
                    <div>Invite your friends to play!</div>
                    <div>Share the link to this game!</div>
                    <input class="link" value="${this.getShareLink()}" readonly/>
                    <button class="copy-to-clipboard-btn"></button>
                </div>
                <div><button class="back-to-lobby">Back to Lobby</button></div>
            </div>
        `;

        this.addEventListener('.back-to-lobby', 'click', () => {
            this.hide();
            this.game.showLobby();
        });

        this.addEventListener('.start-game', 'click', () => {
            this.hide();
            this.game.start();
        })

        this.addEventListener('.copy-to-clipboard-btn', 'click', () => {
            this.withElements('.share-link .link', elem => {
                const inputElem = elem as HTMLInputElement;
                const textToCopy = inputElem.value;

                const okToast = () => new Toaster('Link Copied to Clipboard !');
                const errToast = () => new Toaster('Something went wrong  !');
                if (!navigator.clipboard){
                    try {
                        inputElem.select();
                        document.execCommand("Copy");
                        okToast();
                    }
                    catch (e) {
                        errToast();
                    }
                } else{
                    navigator.clipboard.writeText(textToCopy).then(
                        function(){
                            okToast();
                        })
                        .catch(
                            function() {
                                errToast();
                            });
                }
            })
        })

    }

    updateElement() {
        super.updateElement();
        this.players = this.game.getPlayers();
        this.playersRubrics?.setPlayers(this.players);

        this.withElements('.start-game', startBtn => {
            (startBtn as HTMLButtonElement).disabled = this.game.stage === GameStage.Seating;
        })

        this.withElements('.game-name', elem => {
            elem.innerText = `Seating area for game: ${this.game.name}`;
        })
    }

    public initRubrics() {
        if (this.playersRubrics) {
            return;
        }

        this.withElements('.players-wrap-seating', elem => {
            this.playersRubrics = new PlayersRubricsComponent(elem);
            this.addSubComponent(this.playersRubrics);
            this.playersRubrics.setMode(RubricsMode.Seating);
            this.playersRubrics.onSeat(seatedPlayer => {
                this.game.serverComm.execCommand(ServerCommand.Seat, {
                    playerId: seatedPlayer.id
                })
            })
        })
    }

    private getShareLink() {
        return location.origin + location.pathname + location.hash;
    }
}