import "../assets/css/normalize.css";
import "./app/global-styles.scss";

import {App} from "./app";

if (window.top && window.top !== window) {
    document.body.innerHTML='<h2>Redirection error. please go to <a href="https://www.strategus-game.com" target="_blank">https://www.strategus-game.com</a></h2>';
}
else {
    window.addEventListener('DOMContentLoaded', () => {
        new App();
    })
}

