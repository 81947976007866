import {UIComponent} from "../../infra/UIComponent";
import {Region} from "./index";
import {WeaponChooser} from "./WeaponChooser";
import {Helpers} from "../../../../common/Helpers";
import {WeaponOrder, WeaponSymbols} from "../../../../common/common-types";

export enum ControlsMode {
    Default,
    SelectTarget,
    Draft,
    WeaponChoose,
    WeaponQuantityInput,
    MissileChoose,
    CivilianMove,
    ArmyMove,
}

export class RegionControls extends UIComponent {
    get firingMissile() {
        const board = this.region.game.board;
        return board.missileTargetMode && board.missileTargetModeFiringRegions?.includes(this.region);
    }

    get firingMissileFromOtherRegion() {
        const board = this.region.game.board;
        return board.missileTargetMode && board.missileTargetModeFiringRegions?.find(rgn => rgn.currentOwner === this.region.currentOwner);
    }

    get mode(): ControlsMode {
        const board = this.region.game.board;
        if (board.targetSelectionMode &&
            board.getMoveDetails()?.source?.regionId !== this.region.regionId) {
            const player = board.getMoveDetails()?.retreating?.moveDetails?.owner || board.getMoveDetails()?.owner;
            if (!board.missileTargetMode &&
                (player.id === this.region.currentOwner?.id || !player.alliance || player.alliance !== this.region.currentOwner?.alliance) &&
                (!this.region.regionData?.currentOwner ||
                player.id === this.region.currentOwner?.id ||
                board.getMoveDetails()?.type === 'army' ||
                board.getMoveDetails()?.retreating?.moveDetails?.type === 'army')) {

                return ControlsMode.SelectTarget
            }

            if (board.missileTargetMode &&
                this.region.regionData?.currentOwner &&
                (!this.region.currentOwner?.alliance || this.region.currentOwner?.alliance !== board.missileTargetModeFiringRegions?.[0]?.currentOwner?.alliance) &&
                this.region.regionData.currentOwner !== board.missileTargetModeFiringRegions?.[0]?.regionData?.currentOwner) {

                const weaponDescriptor = this.region.game.getWeaponsDescriptorBySymbol(board.missileTargetModeWeaponSymbol as string);

                if (weaponDescriptor?.range && weaponDescriptor?.range >= Math.min(...board.missileTargetModeFiringRegions.map(rgn => this.region.getDistanceToRegion(rgn)))) {
                    return ControlsMode.SelectTarget
                }
            }
        }

        return this._mode;
    }

    private _mode: ControlsMode = ControlsMode.Default;
    private weaponChooser: WeaponChooser | null = null;
    private _selectedMissileSymbol: string | null = null;
    private _chosenWeaponSymbol = '';

    constructor(private region: Region, selector: string) {
        super(region.element.querySelector(selector) as HTMLElement);
    }

    initElement(): void {
        this.element.innerHTML = `
            <div class="region-data">
                <div class="region-key-data">
                    <div class="region-data-item"><div class="growth"></div></div>   
                    <div class="region-data-item"><div class="defence-bonus"></div></div>   
                    <div class="region-data-item with-buttons">
                        <div class="civilians"></div>
                        <button class="mini-button move-civilians-btn" title="Move Civilians"><i class="bi bi-arrows-move"></i></button>
                    </div>   
                    <div class="region-data-item with-buttons">
                        <div class="army"></div>
                        <div class="buttons">
                            <button class="mini-button draft-btn" title="Recruit Civilians to the Army"><i class="bi bi-plus-lg"></i></button>
                            <button class="mini-button move-army-btn" title="Move Army"><i class="bi bi-arrows-move"></i></button>
                        </div>   
                    </div>   
                </div>
                <div class="weapons-wrapper">
                    <div class="weapons"></div>
                    <button class="mini-button fire-btn" title="Fire Missiles"><i class="bi bi-rocket-takeoff"></i></button>
                    <button class="mini-button arm-btn" title="Buy Regional Weapons"><i class="bi bi-plus-lg"></i></button>
                </div>   
            </div>
            <div class="weapon-choose-mode">
                <div class="weapon-chooser"></div>
                <button class="btn back-btn">👈</button>
            </div>
            </div>
            <div class="select-target-mode">
                <button class="btn select-target-btn"></button>
            </div>
            <div class="input-number-mode">
                <input placeholder="How many?" class="number-input" />
                <button class="input-enter">-></button>
            </div>
        `

        this.weaponChooser = new WeaponChooser(this.region, '.weapon-chooser', chosenSymbol => {
            if (this.mode === ControlsMode.WeaponChoose) {
                this._chosenWeaponSymbol = chosenSymbol;
                this.setMode(ControlsMode.WeaponQuantityInput);
                this.resetAndFocusInput();
            }
            else if (this.mode === ControlsMode.MissileChoose) {
                (this.weaponChooser as WeaponChooser).setMissileSelectionMode(false);
                this._selectedMissileSymbol = chosenSymbol;
                this.setMode(ControlsMode.Default);
                this.onClickFireButton();
            }
        })

        this.addSubComponent(this.weaponChooser);

        this.setClickListeners({
            '.move-civilians-btn': (evt) => {
                evt.stopPropagation();
                this.setMode(ControlsMode.CivilianMove);
                this.resetAndFocusInput();
            },
            '.move-army-btn': (evt) => {
                evt.stopPropagation();
                this.setMode(ControlsMode.ArmyMove);
                this.resetAndFocusInput();
            },
            '.select-target-btn': (evt) => {
                evt.stopPropagation();
                this.region.game.board.onTargetSelected(this.region);
                this.setMode(ControlsMode.Default);
            },
            '.back-btn': (evt) => {
                evt.stopPropagation();
                this.setMode(ControlsMode.Default);
                this.weaponChooser?.setMissileSelectionMode(false);
            },
            '.draft-btn': (evt) => {
                evt.stopPropagation();
                this.setMode(ControlsMode.Draft);
                this.resetAndFocusInput();
            },
            '.arm-btn': (evt) => {
                evt.stopPropagation();
                this.setMode(ControlsMode.WeaponChoose);
            },
            '.input-enter': (evt) => {
                evt.stopPropagation();
                this.onNumberInputEnter()
            },
            '.fire-btn': (evt) => {
                evt.stopPropagation();

                this.onClickFireButton((evt as MouseEvent).shiftKey);
            }
        });

        this.addEventListener('.number-input', 'keydown', (event) => {
            const evt = event as KeyboardEvent;

            switch (evt.code) {
                case 'Enter': {
                    const value = this.getNumberInputValue() as number;

                    if (value !== null && value === Math.floor(value) &&
                        value <= this.getNumberInputMaxValue()) {

                        this.onNumberInputEnter();
                    }
                    break;
                }
                case 'Escape': {
                    this.setMode(ControlsMode.Default);
                    break;
                }
            }
        })

    }

    private onClickFireButton(clearSelectedMissile = false) {

        if (this.firingMissile) {
            this.region.game.board.escape();
            return;
        }

        if (clearSelectedMissile) {
            this._selectedMissileSymbol = null;
        }

        const availableMissiles = this.region.getAvailableMissiles();

        if (availableMissiles.length === 1) {
            this._selectedMissileSymbol = availableMissiles[0];
        }
        else {
            if (!this._selectedMissileSymbol) {

                (this.weaponChooser as WeaponChooser).setMissileSelectionMode(true);
                this.setMode(ControlsMode.MissileChoose);

                return;
            }
        }

        const fireOnce = () => {
            this.region.game.board.selectMissileTarget(this.region, this._selectedMissileSymbol as string, selectedRegion => {
                const range = this.region.game.getWeaponsDescriptorBySymbol(this._selectedMissileSymbol as string)?.range as number;
                if (range >= this.region.getDistanceToRegion(selectedRegion)) {
                    this.region.fireMissile(selectedRegion, this._selectedMissileSymbol as string);
                    if (this.region.weapons[this._selectedMissileSymbol as string] > 1) {
                        requestAnimationFrame(() => {
                            fireOnce();
                        })
                    }
                    else {
                        this._selectedMissileSymbol = null;
                    }
                }
                else {
                    this._selectedMissileSymbol = null;
                }
            });
        }
        fireOnce();
    }

    public escape() {
        this.setMode(ControlsMode.Default);
        this.weaponChooser?.setMissileSelectionMode(false);
        this._selectedMissileSymbol = null;
    }

    private getNumberInputValue(): number | null {
        let value = null;
        this.withElements('.number-input', (elem) => {
            if (!this.region.regionData) {
                return;
            }
            const input = elem as HTMLInputElement;
            const lowerCase = input.value.toLowerCase()
            const maxValue = this.getNumberInputMaxValue();
            const multi = lowerCase.endsWith('k') ? 1000 : lowerCase.endsWith('m') ? 1000000 : lowerCase.endsWith('b') ? 1000000000 : 1;
            value = lowerCase.endsWith('%') ? Math.floor(maxValue * parseFloat(input.value) / 100): parseFloat(input.value) * multi;
        })

        return value;
    }

    private getNumberInputMaxValue() {
        if (!this.region.regionData) {
            return 0;
        }

        return this.mode === ControlsMode.WeaponQuantityInput ?
            this.getMaxForWeaponQuantity() :
            (this.mode === ControlsMode.ArmyMove ? this.region.regionData.currentArmyForces : this.region.regionData.currentPopulation);
    }

    private onNumberInputEnter() {
        const value = this.getNumberInputValue() as number;

        if (!value) {
            return;
        }

        switch (this.mode) {
            case ControlsMode.ArmyMove:
            case ControlsMode.CivilianMove: {
                this.region.game.board.move(
                    this.region,
                    value,
                    this.mode === ControlsMode.CivilianMove ? 'civilian' : 'army'
                );
                this.setMode(ControlsMode.Default);
                break;
            }
            case ControlsMode.Draft: {
                this.region.draft(value);
                this.region.updateElement();
                this.setMode(ControlsMode.Default);
                break;
            }
            case ControlsMode.WeaponQuantityInput: {
                this.region.installWeapon(this._chosenWeaponSymbol, value);
                this.region.updateElement();
                this.setMode(ControlsMode.Default);
                break;
            }
        }
    }

    private getMaxForWeaponQuantity() {
        return Math.floor(this.region.currentOwner?.goldCoins as number / (this.region.game.getWeaponsDescriptorBySymbol(this._chosenWeaponSymbol)?.price as number));
    }

    updateElement(): void {
        super.updateElement();

        if (!this.region.regionData) {
            return;
        }

        const data = this.region.regionData

        this.withElements('.region-data .region-data-item .civilians', elem => {
            elem.innerHTML = `<div title="Civilians" class="stat-icon civilians-icon"></div> <div class="stat-value">${ Helpers.abbreviateNumber(+data.currentPopulation.toFixed(0)) } / ${ Helpers.abbreviateNumber(data.maximumPopulation) }</div>`;
        })

        this.withElements('.region-data .region-data-item .army', elem => {
            elem.innerHTML = `<div title="Armed Forces" class="stat-icon armed-forces-icon"></div> <div class="stat-value">${ Helpers.abbreviateNumber(data.currentArmyForces) }</div>`;
        })

        this.withElements('.region-data .region-data-item .defence-bonus', elem => {
            elem.innerHTML = `<div title="Defence Bonus" class="stat-icon defence-bonus-icon"></div> <div class="stat-value">${ data.defensiveBonusFactor.toFixed(1) } / ${ data.originalDefensiveBonusFactor.toFixed(1) }</div>`;
        })

        this.withElements('.region-data .region-data-item .growth', elem => {
            elem.innerHTML = `<div title="Civilian Population Growth" class="stat-icon growth-icon"></div> <div class="stat-value">${ data.growthPerCycleMean.toFixed(1) } [${ data.growthPerCycleStdDev.toFixed(1) }]</div>`;
        })

        this.withElements('.weapons', elem => {
            const weaponsArray = Object.entries(this.region.weapons);
            elem.innerHTML = this.arrayToHTML(weaponsArray, (([weaponSymbol, quantity]) => {
                const inOrder = this.region.regionData?.weaponOrders.find(o => o.weaponSymbol === weaponSymbol) as WeaponOrder;

                if (!inOrder && !quantity) {
                    return '';
                }

                const activeGuerrilla = weaponSymbol === WeaponSymbols.GR && quantity && this.region.regionData?.currentOwner !== this.region.regionData?.originalOwner;
                const originalOwnerColor = this.region.game.getPlayerDataById(this.region.regionData?.originalOwner)?.color;

                return `<div class="weapon-inventory-item  ${inOrder ? 'in-order' : ''}${activeGuerrilla ? 'active-guerrilla' : ''}" ${activeGuerrilla ? `style="background-color: ${originalOwnerColor}"` : ''}><span class="weapon-symbol">${weaponSymbol}:</span><span class="weapon-quantity">${Helpers.abbreviateNumber(quantity)} ${inOrder ? `(${Helpers.abbreviateNumber(inOrder.quantityOrdered - inOrder.quantityDelivered)})` : ''}</span></div>`;

            }), ' ');
        })

        this.setElementDisplayMode(
            '.region-data',
            this.mode !== ControlsMode.WeaponChoose ? 'flex' : 'none'
        );

        this.setElementDisplayMode(
            '.select-target-mode',
            this.mode === ControlsMode.SelectTarget ? 'inline-block' : 'none'
        );

        this.withElements('.select-target-btn', selectTargetBtn => {
            selectTargetBtn.innerText = this.getSelectTargetButtonText();
        })

        this.withElements('.number-input', elem => {
            const input = elem as HTMLInputElement;
            if (this.mode === ControlsMode.WeaponQuantityInput) {
                input.placeholder = 'How many? (' + Helpers.abbreviateNumber(this.getMaxForWeaponQuantity()) + ' max)'
            }
            else {
                input.placeholder = 'How many?';
            }
        })

        this.setElementDisplayMode(
            '.input-number-mode',
            !this.region.game.board.targetSelectionMode &&
            [ControlsMode.CivilianMove, ControlsMode.ArmyMove, ControlsMode.Draft, ControlsMode.WeaponQuantityInput].includes(this.mode) ? 'flex' : 'none'
        );

        this.setElementDisplayMode(
            '.mini-button',
            !this.region.currentOwner?.isAI &&
            this.region.game.userId === this.region.currentOwner?.userId &&
            !this.region.currentOwner?.surrendered &&
            !this.region.game.board.targetSelectionMode &&
            this.mode === ControlsMode.Default ? 'inline-block' : 'none'
        );

        this.setElementDisplayMode(
            '.weapons-wrapper',
            (!this.region.game.board.targetSelectionMode || this.firingMissile || this.firingMissileFromOtherRegion) &&
            this.mode === ControlsMode.Default ? 'flex' : 'none'
        );

        this.setElementDisplayMode(
            '.move-btn',
            +this.region.regionData.currentPopulation.toFixed(0) > 0 ||
            +this.region.regionData.currentArmyForces.toFixed(0) > 0 ? 'inline-block' : 'none'
        );

        this.setElementDisplayMode(
            '.fire-btn',
            !this.region.currentOwner?.surrendered &&
            !this.region.currentOwner?.isAI &&
            this.region.game.userId === this.region.currentOwner?.userId &&
            (!this.region.game.board.targetSelectionMode || this.firingMissile || this.firingMissileFromOtherRegion) &&
            this.mode === ControlsMode.Default &&
            this.region.regionData.currentOwner &&
            this.region.getAvailableMissiles().length ? 'inline-block' : 'none'
        );

        this.withElements('.fire-btn', btn => {
            btn.classList[this.firingMissile ? 'add' : 'remove']('blinking');
        })

        this.setElementDisplayMode(
            '.weapon-choose-mode',
            this.mode === ControlsMode.WeaponChoose || this.mode === ControlsMode.MissileChoose ? 'block' : 'none'
        );

        this.withElements('.weapon-choose-mode', elem => {
            if (this.weaponChooser?.missilesSelectionMode) {
                elem.classList.add('missile-selector');
            }
            else {
                elem.classList.remove('missile-selector');
            }
        })

        /* this.region.regionData.currentOwner?.goldCoins > 10*/
    }

    private getSelectTargetButtonText() {
        return !this.region.regionData?.currentOwner ||
        this.region.game.board.getMoveDetails()?.owner?.name === this.region.currentOwner?.name ||
        (this.region.game.board.getMoveDetails()?.owner?.alliance && this.region.game.board.getMoveDetails()?.owner?.alliance === this.region.currentOwner?.alliance) ||
        this.region.game.board.getMoveDetails()?.retreating?.moveDetails?.owner?.name === this.region.currentOwner?.name ?
            'Select Target' : 'Attack !'
    }

    public setMode(mode: ControlsMode, update = true) {
        this._mode = mode;
        if (update) {
            this.updateElement();
            this.parent?.updateElement();
        }
    }

    private resetAndFocusInput() {
        this.withElements('.number-input', input => {
            (input as HTMLInputElement).value = '';
            input.focus();
        })
    }

}
