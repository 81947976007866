interface IParticle {
    x: number;
    y: number;
    size: number;
    speedX: number;
    speedY: number;
    update(): void;
    draw(): void;
}

class Particle implements IParticle {
    size: number;
    speedX: number;
    speedY: number;

    constructor(public x: number, public y: number, private ctx: CanvasRenderingContext2D) {
        this.size = Math.random() * 5 + 1;
        this.speedX = Math.random() * 6 - 3;
        this.speedY = Math.random() * 6 - 3;
    }

    update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.size > 0.1) this.size -= 0.1;
    }

    draw() {
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        this.ctx.closePath();
        this.ctx.fill();
    }
}

export class ParticlesExplosionEffect {
    private particles: Particle[] = [];
    private numParticles = 100;
    private ctx: CanvasRenderingContext2D;
    public triggered = false;
    public done = false;
    private onDoneCallback: () => void = () => {/**/}

    constructor(canvas: HTMLCanvasElement) {
        this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        this.ctx.fillStyle = "gold";
        this.animateParticles();
    }

    public createExplosion(onDone = () => {/**/}, xPos: number = this.ctx.canvas.width / 2, yPos: number = this.ctx.canvas.height / 2) {
        this.onDoneCallback = onDone;
        for (let i = 0; i < this.numParticles; i++) {
            this.particles.push(new Particle(xPos, yPos, this.ctx));
        }
        this.triggered = true;
    }

    private animateParticles() {
        if (this.triggered && !this.particles.length) {
            this.onDoneCallback();
            this.done = true;
        }
        if (this.particles.length) {
            this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        }
        for (let i = this.particles.length - 1; i >= 0; i--) {
            this.particles[i].update();
            this.particles[i].draw();

            if (this.particles[i].size <= 0.1) {
                this.particles.splice(i, 1);
            }
        }

        if (!this.done) requestAnimationFrame(() => this.animateParticles());
    }
}
