import {StrategusGame} from "../client/src/app/Game";
import {IInterceptable} from "../client/src/app/Region/types";
import {AutoMoverType} from "./server-command";

export interface Dictionary<T> {
    [index: string]: T;
}

export type PlayerId = string;
export type RegionId = string;
export type TransportId = string;
export type MissileId = string;

export interface PlayerData {
    id: PlayerId;
    alliance?: string | null;
    isAI: boolean;
    autoMover?: AutoMoverType;
    name: string;
    color: string;
    goldCoins: number;
    surrendered: boolean;
    nuclearAttackers: PlayerId[];
    userId?: string | null;
}

export interface WeaponOrder {
    weaponSymbol: string,
    quantityOrdered: number,
    quantityDelivered: number,
    fractionDone: number
}

export interface RegionData {
    regionId: RegionId;
    regionName: string;
    homeFor: PlayerId | null;
    currentOwner: PlayerId | null;
    originalOwner: PlayerId | null;
    value: number;
    maximumPopulation: number;
    growthPerCycleMean: number; // average percentage of population added each cycle
    growthPerCycleStdDev: number; // stdDev for percentage of population added each cycle
    originalDefensiveBonusFactor: number; // force is multiplied by this number when defending
    defensiveBonusFactor: number; // force is multiplied by this number when defending
    radiation: number;

    currentPopulation: number;
    currentArmyForces: number;
    currentlyInDraftProcess: number;

    weapons: Dictionary<number>;
    weaponOrders: WeaponOrder[];

    positionX?: number,
    positionY?: number,

    polygonPoints: { x: number, y: number }[];
}

export type TransportType = 'civilian' | 'army'

export interface TransportData {
    transportId: TransportId
    type: TransportType
    sourceId: RegionId,
    targetId: RegionId,
    amount: number,
    owner: PlayerId,
    battleMode: boolean,
    battleStation: number
    positionX: number,
    positionY: number,
}

export interface MissileData {
    missileId: MissileId,
    missileSymbol: string,
    sourceId: RegionId,
    targetId: RegionId,
    owner: PlayerId,
    positionX: number,
    positionY: number,
    angle: number | null
}

export interface ListGamesResponseData {
    id: string,
    name: string,
        numberOfPlayers: number,
    stage: GameStage,
    cycleNumber: number
}

export enum WeaponType {
    Interceptor = 'interceptor',
    Missile = 'missile',
}

export interface WeaponDescriptor {
    type: WeaponType,
    name: string,
    lettersSymbol: string,
    price: number,
    range?: number,
    damageFactor?: number,
    isRadioactive?: boolean,
    intercepts?: { new(game: StrategusGame, initialData?: object): IInterceptable }
    interceptabilityFactor?: number
}

export enum GameStage {
    Initial,
    Seating,
    Ready,
    OnGoing,
    Saved
}

export interface UserData {
    nickname: string
}

export class Vector {
    constructor(public x = 0, public y = 0) {
    }

    public isEqual(v: Vector) {
        return this.x === v.x && this.y === v.y;
    }

    public get size(): number {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2))
    }

    public distanceTo(otherVector: Vector) {
        return Math.sqrt(Math.pow(otherVector.y - this.y, 2) + Math.pow(otherVector.x - this.x, 2));
    }
}

export enum WeaponSymbols {
    TI= 'TI',
    MI= 'MI',
    SM= 'SM',
    BM= 'BM',
    HS= 'HS',
    NM= 'NM',
    HB= 'HB',
    GR= 'GR',
    AS= 'AS',
}
