import {io, Socket} from "socket.io-client";
import {ServerCommand} from "../../../../common/server-command";
import {StrategusGame} from "../../app/Game";
import {ClientCommand} from "../../../../common/client-command";
import {Dictionary} from "lodash";

export class ServerComm {
    private socket: Socket = io();
    private callbacks: Dictionary<(data: any) => void> = {}; /* eslint-disable-line @typescript-eslint/no-explicit-any*/

    constructor(private game: StrategusGame) {
        this.socket.onAny((cmd: string, data: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any*/
            if (this.callbacks[cmd]) {
                this.callbacks[cmd](data);
                delete this.callbacks[cmd];
            }
            else {
                this.game.handleCommand(ClientCommand[cmd as any] as any, data); /* eslint-disable-line @typescript-eslint/no-explicit-any*/
            }
        })
    }

    public execCommand(command: ServerCommand, commandData: object = {}) {
        this.socket.emit(ServerCommand[command], commandData);
    }

    public execCommandAndGetResponse(command: ServerCommand, commandData: object = {}, timeout = 8000): Promise<any> { /* eslint-disable-line @typescript-eslint/no-explicit-any*/
        return new Promise((resolve, reject) => {
            const to = setTimeout(() => {
                reject();
            }, timeout);

            this.callbacks[ServerCommand[command] + ':response'] = data => {
                clearTimeout(to);
                resolve(data);
            }
            this.execCommand(command, commandData);
        })
    }
}