import {ComponentOptions} from "./types";
import {compact, Dictionary} from "lodash";
import {AccessorsObject, Bindings} from "../Bindings";

export abstract class UIComponent {

    private _element: HTMLElement;
    private _subComponents: UIComponent[] = [];
    private _parent: UIComponent | null = null;
    private _displayBeforeHide = '';
    private _originalElementCreator: HTMLElement | string | (() => HTMLElement);
    private _bindings: Dictionary<AccessorsObject> | null = null;

    get bindings(): Dictionary<AccessorsObject> {
        return this._bindings as Dictionary<AccessorsObject>;
    }

    get element(): HTMLElement {
        return this._element;
    }

    get parent(): UIComponent | null {
        return this._parent;
    }

    protected constructor(_element: HTMLElement | string | (() => HTMLElement), options: ComponentOptions = {}) {
        this._originalElementCreator = _element;

        this._element = this.getElement();

        if (!this._element) {
            throw ('Could not find element ' + _element)
        }

        if (options.startHidden) {
            this.hide();
        }

        if (!options.manualInitialization) {
            requestAnimationFrame(() => {
                    this.initElement();
                    this.updateElement();
            })
        }
    }

    public abstract initElement(): void;

    protected onShowElement() {
        //
    }

    private getElement() {
        return typeof this._originalElementCreator === 'string' ? document.querySelector(this._originalElementCreator) as HTMLElement : typeof this._originalElementCreator === 'function' ? this._originalElementCreator() : this._originalElementCreator;
    }

    protected updateElementReference() {
        this._element = this.getElement() || this._element;
    }

    public updateElement(): void {
        this.updateElementReference();
        this._subComponents.forEach(subComponent => {
            subComponent.updateElement();
        })
    }

    public setRootElement(newRoot: HTMLElement) {
        this._element = newRoot;
        this.initElement();
    }

    public withElements(selector: string, cb: (element: HTMLElement) => void) {
        if (selector) {
            this.element.querySelectorAll(selector).forEach(elem => {
                cb(elem as HTMLElement);
            })
        }
        else {
            cb(this.element);
        }

    }

    public addEventListener(selector: string, eventName: string, cb: (evt: Event) => void) {
        this.withElements(selector, (element: HTMLElement) => {
            element.addEventListener(eventName, cb);
        });
    }

    public setEventListeners(eventsObject: Dictionary<Dictionary<(...args: any[]) => void>>) {
        Object.entries(eventsObject).forEach(([selector, events]) => {
            Object.entries(events).forEach(([eventType, callback]) => {
                this.addEventListener(selector, eventType, callback);
            })
        })
    }

    public setClickListeners(eventsObject: Dictionary<(...args: any[]) => void>) {
        this.setEventListeners(Object.fromEntries(Object.entries(eventsObject).map(([selector, callback]) => {
            return [selector, {
                'click': callback
            }];
        })))
    }

    public querySelector(selector: string) {
        return this.element.querySelector(selector) as HTMLElement;
    }

    public querySelectorAll(selector: string) {
        return Array.from(this.element.querySelectorAll(selector)) as HTMLElement[];
    }

    public setElementDisplayMode(selector: string, displayModeValue: string | ((elem: HTMLElement) => string)) {
        this.withElements(selector, elem => {
            elem.style.display = typeof displayModeValue === 'string' ? displayModeValue : displayModeValue(elem);
        })
    }

    public setElementVisibilityMode(selector: string, visibilityModeValue: string | ((elem: HTMLElement) => string)) {
        this.withElements(selector, elem => {
            elem.style.visibility = typeof visibilityModeValue === 'string' ? visibilityModeValue : visibilityModeValue(elem);
        })
    }

    public addSubComponent(subComponent: UIComponent) {
        this._subComponents.push(subComponent);
        subComponent.setParent(this);
    }

    protected removeSubComponent(subComponent: UIComponent) {
        if (!this._subComponents.includes(subComponent)) {
            return;
        }

        this._subComponents = this._subComponents.filter(sc => sc !== subComponent);
        subComponent.setParent(null);
    }

    public setParent(parent: UIComponent | null) {
        this._parent = parent;
    }

    public destroy(): void {
        if (this.parent) {
            this.parent.removeSubComponent(this);
        }
        this._subComponents.forEach(subComponent => {
            subComponent.destroy();
        })
        this.element.remove();
    }

    public hide() {
        if (this.element.style.display !== 'none') {
            this._displayBeforeHide = this.element.style.display;
        }
        this.element.style.display = 'none';
    }

    public show() {
        if (this.element.style.display !== this._displayBeforeHide) {
            this.element.style.display = this._displayBeforeHide;
            this.onShowElement();
        }
    }

    public postMessage(msg: string, data?: any) {
        this.onMessage(msg, data);
    }

    protected onMessage(msg: string, data?: any) {
        //
    }

    protected createBindings(bindingsDefinition: Dictionary<string>) {
        const entries = Object.entries(bindingsDefinition);
        const newEntries = entries.map(([fieldName, selector]) => {
            const elem = this.querySelector(selector);
            if (elem) {
                return [fieldName, elem];
            }
            throw Error('Element could not be found: ' + selector);
        });

        const bindingsDefinitionWithElements = Object.fromEntries(newEntries)
        const b = new Bindings(bindingsDefinitionWithElements);

        this._bindings = b.createAccessors();
    }

    protected arrayToHTML(array: any[], itemToHTMLFunction: (item: any, idx: number) => string, joiner = ''): string { /* eslint-disable-line @typescript-eslint/no-explicit-any*/
        return compact(array.map(itemToHTMLFunction)).join(joiner);
    }

}