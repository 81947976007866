import './toaster.scss';
import {UIComponent} from "../UIComponent";

export class Toaster extends UIComponent {

    constructor(private content: string) {
        super((() => {
            const existing = document.querySelector('.toaster-container');

            if (existing) {
                return existing as HTMLElement;
            }

            const e = document.createElement('div');
            e.className = 'toaster-container';
            document.body.appendChild(e);
            return e;
        })());
    }

    initElement(): void {
        setTimeout(() => {
            this.element.innerHTML = this.content;
            this.element.classList.add('pop');

            setTimeout(() => {
                this.element.classList.remove('pop');
                setTimeout(() => {
                    this.destroy();
                }, 500)
            }, 2500);
        }, 100);
    }
}