import {Dialog} from "../../infra/Dialog";
import {ServerCommand} from "../../../../common/server-command";
import {StrategusGame} from "../Game";

export class UserDialog extends Dialog {
    constructor(game: StrategusGame, newUser = true) {
        super(`
                <div class="standard-dialog">
                    <div class="dialog-title">${newUser ? 'Welcome to Strategus!' : 'Change your nickname'}</div>
                    <div class="dialog-body welcome-dialog">
                        <div>Hello there, ${game.userData?.nickname}!</div>
                        <input class="nickname-input close-on-enter" placeholder="please enter your ${newUser ? '' : 'new ' }nickname">
                        ${newUser ? `
                            <div class="small-text">This is optional, and can be changed in any time.</div>
                            <div class="small-text">"Player" will be your anonymous nickname, if you'll skip.</div>
                        ` : ``}
                    </div>
                </div>
            `, {
                'Done': () => {
                    this.close();
                }
            },
            {
                backdropClickCloses: false
            }
        );

        this.whenReady.then(() => {
            this.createBindings({
                nickname: '.nickname-input'
            })
            const updateButtonText = () => {
                (this.getButton('Done') as HTMLElement).innerText = this.bindings.nickname.value ? 'Done' : 'Skip';
            }
            updateButtonText();
            this.bindings.nickname.onChange(() => {
                updateButtonText();
            })

            this.bindings.nickname.element.focus();
        })

        this.whenClosed.then(async () => {
            const nickname = this.bindings.nickname.value || game.userData?.nickname || 'Player';
            const ok = await game.serverComm.execCommandAndGetResponse(ServerCommand.SetTempUserData, {
                nickname
            });
            if (ok) {
                game.setUserData({nickname});
                game.app.router.getCurrentComponent()?.updateElement();
            }
        })
    }
}