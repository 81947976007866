import {Board} from "../Board";
import {Region} from "../Region";
import {Vector} from "../../../../common/common-types";
import {Polygon} from "../../../../common/Polygon";

export class GameMap {

    get mapRect() {
        return this.mapRootElement.getBoundingClientRect();
    }

    private static svgns = 'http://www.w3.org/2000/svg';

    private svgRoot: SVGElement | null = null;

    constructor(private board: Board, private mapRootElement: HTMLElement) {

    }

    update() {
        this.mapRootElement.innerHTML = '';
        this.svgRoot = this.createSVGRootElement(this.mapRect.width, this.mapRect.height);
        this.mapRootElement.appendChild(this.svgRoot);

        const polygons: {region: Region, polygon: Polygon}[] = [];
        this.board.regions.forEach(region => {
            if (region.regionData) {
                polygons.push({region, polygon: new Polygon(region.regionData?.polygonPoints.map(pt => new Vector(pt.x, pt.y)))})
            }
        })

        polygons.forEach(polygon => {
            const polygonSVG = this.createSvgPolygon(polygon.polygon);
            polygon.region.mapSvg = polygonSVG as SVGElement;
            this.svgRoot?.appendChild(polygonSVG);
        })

        setTimeout(() => {
            this.mapRootElement.classList.add('game-running');
        }, 1000);
    }

    private createSVGRootElement(width: number, height: number): SVGElement {
        const svg = document.createElementNS(GameMap.svgns, 'svg');
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
        svg.setAttribute('preserveAspectRatio', 'none');
        return svg as SVGElement;
    }

    private createSvgPolygon(polygon: Polygon) {
        const strPoints = polygon.pts.map(pt => ({x: pt.x * this.mapRect.width / 100, y: pt.y * this.mapRect.height / 100})).reduce((str, pt) => str + `${pt.x},${pt.y} `, '').trim();

        const svgPolygon = document.createElementNS(GameMap.svgns, 'polygon');
        svgPolygon.setAttributeNS(null, 'points', strPoints);
        svgPolygon.setAttributeNS(null, 'fill', 'currentColor');
        svgPolygon.setAttributeNS(null, 'stroke', 'darkblue');
        svgPolygon.setAttributeNS(null, 'stroke-width', '10px');

        return svgPolygon;
    }
}